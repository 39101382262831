/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'outsourced-member': {
    width: 16,
    height: 16,
    viewBox: '0 0 1024 1024',
    data: '<path pid="0" d="M573.1 608.5c8.2 6.1 9.9 17.7 3.8 25.9s-17.7 9.9-25.9 3.8c-36.9-27.4-56.6-72.4-62-118.7-1.2-10.2 6.1-19.4 16.2-20.6s19.4 6.1 20.6 16.2c4.4 37.2 20.1 73.1 47.3 93.4zM368 513.1c1.2-10.2 10.4-17.4 20.6-16.2 10.2 1.2 17.4 10.4 16.2 20.6-7.3 61.7-38 117.6-98.2 139.5-94.5 30.4-128.2 61.8-133.1 140.6-.6 10.2-9.5 18-19.7 17.3-10.2-.6-18-9.5-17.3-19.7 6.1-96.9 51.3-139 158.1-173.4 43.7-15.8 67.6-59.4 73.4-108.7z" _fill="#0D253E"/><path pid="1" d="M446.9 509.2c81.5 0 148.3-66.2 148.3-146.8V321c0-80.7-66.7-146.8-148.3-146.8S298.6 240.3 298.6 321v41.3c0 80.7 66.8 146.9 148.3 146.9zm0 37c-102 0-185.4-82.7-185.4-183.9V321c0-101.2 83.4-183.9 185.4-183.9S632.3 219.8 632.3 321v41.3c0 101.3-83.4 183.9-185.4 183.9zM710.38 886.18c-96.8 0-175.2-78.4-175.2-175.2s78.4-175.2 175.2-175.2 175.2 78.4 175.2 175.2-78.5 175.2-175.2 175.2zm0-37.1c76.3 0 138.1-61.8 138.1-138.1s-61.9-138.1-138.1-138.1-138.1 61.8-138.1 138.1 61.8 138.1 138.1 138.1z" _fill="#0D253E"/><path pid="2" d="M728.88 692.48h138.1c10.2 0 18.5 8.3 18.5 18.5s-8.3 18.5-18.5 18.5h-156.6c-10.2 0-18.5-8.3-18.5-18.5v-156.6c0-10.2 8.3-18.5 18.5-18.5s18.5 8.3 18.5 18.5v138.1z" _fill="#0D253E"/>'
  }
})
