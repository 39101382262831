/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'course-learning': {
    width: 200,
    height: 200,
    viewBox: '0 0 1024 1024',
    data: '<path pid="0" d="M512.5 936.6L355.33 810.52h-156.3c-40.81 0-74.02-35.96-74.02-80.15V205.31c0-44.19 33.21-80.15 74.02-80.15h179.02l134.33 110.51L645.9 125.16h179.07c40.82 0 74.02 35.96 74.02 80.15v525.07c0 44.19-33.21 80.15-74.02 80.15H668.73L512.5 936.6zM199.02 189.16c-4.63 0-10.02 7.05-10.02 16.15v525.07c0 9.09 5.39 16.15 10.02 16.15h178.8l134.55 107.93 133.75-107.93h178.85c4.63 0 10.02-7.05 10.02-16.15V205.31c0-9.1-5.39-16.15-10.02-16.15H668.95L512.5 318.64 355.1 189.16H199.02z"/><path pid="1" d="M437.66 669.86c-5.38 0-10.76-1.35-15.62-4.07a32.013 32.013 0 01-16.38-27.93v-233c0-11.59 6.27-22.27 16.38-27.93s22.5-5.4 32.37.66l189.66 116.5a31.997 31.997 0 0115.25 27.27c0 11.12-5.77 21.45-15.25 27.27l-189.66 116.5a31.971 31.971 0 01-16.75 4.73zm32.01-207.78v118.58l96.52-59.29-96.52-59.29z"/>'
  }
})
