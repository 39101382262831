import _Vue from 'vue'
import { Message } from 'element-ui'
// tslint:disable-next-line
const Clipboard = require('clipboard/dist/clipboard.min.js')

declare module 'vue/types/vue' {
  // tslint:disable-next-line:interface-name
  interface Vue {
    $copyToClipboard: (error: any) => void
  }
  // tslint:disable-next-line:interface-name
  interface VueConstructor<V extends Vue> {
    copyToClipboard: (error: any) => void
  }
}

function copyToClipboard(text: string) {
  const fakeElement = document.createElement('button')
  const clipboard = new Clipboard(fakeElement, {
    text: () => {
      return text
    },
    action: () => { 'copy' },
    container: document.body,
  })
  clipboard.on('success', () => {
    clipboard.destroy()
    Message.success('Copy success!')
  })
  clipboard.on('error', () => {
    clipboard.destroy()
    Message.error('Error!')
  })
  fakeElement.click()
}

export default {
  install(Vue: typeof _Vue, options?: any) {
    Vue.copyToClipboard = copyToClipboard
    Vue.prototype.$copyToClipboard = copyToClipboard
  },
}
