































import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  props: {
    isActive: {
      type: Boolean,
      default: false,
    },
    toggleClick: {
      type: Function,
      default: function async() {
        //
      },
    },
  },
  setup() {
    //
  },
})
