<template>
  <div class="wrapper">
    <el-dialog
      width="430px"
      :visible.sync="showBlockRouteVisible"
      :modal="false"
      :modal-append-to-body="false"
      :close-on-click-modal="false"
    >
      <div class="block-route-area">
        <div class="area-title">
          <i class="el-icon-loading" /> Catch page error. Reporting...
        </div>
        <div
          class="area-subtitle"
        >The error message is being reported to the SSO team. After completion, the route will be automatically switched for you. Thank you for your support.</div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { ref, defineComponent, watch, onMounted } from '@vue/composition-api'
import { getErrorLog } from '@/lib/utils/getErrorLog'
import router from '@/router'
import { useRouter } from '@u3u/vue-hooks'
import { useGetScreenshot, useUploadImage, sendNotifyToWebhook } from '@/apis/common/useScreenshot'
import { UserModule } from '@/store/modules/user'

let toPathName = ''
let timer = null

export default defineComponent({
  setup() {
    const showBlockRouteVisible = ref(false)
    const { errorLogStack, clearErrorLogStack } = getErrorLog()
    const { formData, screenshotLoading, screenshotImg } = useGetScreenshot()
    const { imgRes, uploadLoading, uploadHandler } = useUploadImage()
    const { sendLoading, sendResult, sendHandler } = sendNotifyToWebhook()
    const { route: routeInstance } = useRouter()

    // 全局路由守卫: 路由变化时 - 上报当前页错误日志, 为捕获到正确页面, 显示弹窗, 阻塞路由
    router.beforeEach(async (to, from, next) => {
      if (errorLogStack.length) {
        // 登出操作拦截上报
        if (to.name === 'Login') {
          clearTimeout(timer)
          next()
          return
        }
        showBlockRouteVisible.value = true
        // 防止路由与防抖都执行
        clearTimeout(timer)
        toPathName = to.name
        const isHandle = await sendLogHandler()
        if (!isHandle) {
          next()
        } else {
          return false
        }
      } else {
        next()
      }
    })

    const sendLogHandler = async () => {
      // 判断是否在上报冷却范围内: 5分钟
      const origin = JSON.parse(localStorage.getItem('captureErrorJson') || '{}')
      const key = `${UserModule.username}+${routeInstance.value.fullPath}`

      if (origin !== '{}' && origin[key]) {
        const range = +new Date() - origin[key]
        if (range < 360 * 1000) {
          showBlockRouteVisible.value = false
          clearErrorLogStack()
          toPathName = ''
          return
        }
      }

      const currentErrorLogStack = JSON.parse(JSON.stringify(errorLogStack))
      const errorObj = {
        [key]: +new Date(),
      }
      const result = JSON.stringify(Object.assign(origin, errorObj))
      localStorage.setItem('captureErrorJson', result)
      await screenshotImg()
      await uploadHandler(formData.value)
      if (!imgRes.value.image_key) {
        return
      }
      // 不上报: 上报错误时, 用户登录失效/主动 logout
      if (!UserModule.username) {
        showBlockRouteVisible.value = false
        clearErrorLogStack()
        toPathName = ''
        return
      }
      await sendHandler('系统自动捕获', imgRes.value.image_key, currentErrorLogStack, '', showBlockRouteVisible.value)
      if (sendResult.value.StatusCode === 0) {
        clearErrorLogStack()
      }
      if (toPathName) {
        showBlockRouteVisible.value = false
        router.push({ name: toPathName })
        toPathName = ''
      }

      return true
    }

    const debounce = (fn, delay) => {
      return () => {
        if (timer) {
          clearTimeout(timer)
        }
        timer = setTimeout(fn, delay)
      }
    }

    onMounted(() => {
      window.onCollectErrorLog = debounce(sendLogHandler, 10000)
    })

    return { screenshotLoading, uploadLoading, sendLoading, errorLogStack, showBlockRouteVisible }
  },
})
</script>

<style lang="stylus" scoped>
.block-route-area
  position relative
  padding 20px
  height 330px
  display flex
  align-items center
  justify-content center
  flex-direction column
  box-sizing border-box
.area-title
  margin-top 57px
  font-size 19px
  font-weight 500
  color rgba(9, 40, 88, 1)
.area-subtitle
  padding-top 15px
  font-size 15px
  line-height 22px
  word-break break-word
  color rgba(9, 40, 88, 0.9)
/deep/ .el-dialog
  background-image url('~@/assets/images/block-route-bg.png')
  background-size 100% 100%
</style>