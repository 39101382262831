














import { Navbar, AppMain, Sidebar } from './components'
import ResizeMixin from './mixin/ResizeHandler'
import { Component, Vue } from 'vue-property-decorator'
import { mixins } from 'vue-class-component'
import { DeviceType, AppModule } from '@/store/modules/app'
import { UserModule } from '@/store/modules/user'
import ToolArea from '@/views/common/ToolArea.vue'

@Component({
  components: {
    Navbar,
    Sidebar,
    AppMain,
    ToolArea,
  },
})
export default class Layout extends mixins(ResizeMixin) {
  get classObj() {
    return {
      hideSidebar: !this.sidebar.opened,
      openSidebar: this.sidebar.opened,
      withoutAnimation: this.sidebar.withoutAnimation,
      mobile: this.device === DeviceType.Mobile,
    }
  }

  get isHiddenLayout() {
    return this.$route.meta.hiddenLayout === true
  }

  handleClickOutside() {
    AppModule.CloseSideBar(false)
  }

  get isKpUser() {
    return UserModule.is_kp_user
  }
}
