/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'table': {
    width: 64,
    height: 64,
    viewBox: '0 0 1024 1024',
    data: '<defs/><path pid="0" d="M568.6 0h454.9v454.9H568.6V0zm0 568.6h454.9v454.9H568.6V568.6zM0 568.6h454.9v454.9H0V568.6zM0 0h454.9v454.9H0V0z"/>'
  }
})
