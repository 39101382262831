// 实现细长进度条
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import { Route } from 'vue-router'
import { UserModule } from '@/store/modules/user'
import router from '@/router'
import qs from 'querystring'
const whiteList = ['Login', '404', 'Dashboard', 'SetPassword', 'RedirectAuthentication', 'AccountIssue']

router.beforeEach(async (to: Route, from: Route, next: any) => {
  // 显示进度条
  NProgress.start()
  if (to.name) {
    if (whiteList.indexOf(to.name) !== -1) {
      // white list
      next()
    } else {
      if (!UserModule.username) {
        try {
          await UserModule.fetchUserInfo()
          // 已登录 -> 重定向到redirect-authentication页面
          const nextUrl = window.location.href
          const queryObj = qs.parse(window.location.search.replace('?', ''))
          // 有next信息时 && 路由不包括redirect-authentication
          if (queryObj.next && !nextUrl.split('next')[0].includes('redirect-authentication')) {
            router.replace({ name: 'RedirectAuthentication', query: { next: queryObj.next } })
            return
          }
        } catch (e) {
          // 若未登录, 重定向到login
          const nextUrl = window.location.href
          const queryObj = qs.parse(window.location.search.replace('?', ''))
          // 有next信息时 && 路由不包括login
          if (queryObj.next && !nextUrl.split('next')[0].includes('login')) {
            router.replace({ name: 'Login', query: { next: queryObj.next } })
            return
          }
          // 没有next信息 && 路由不包括login
          if (!queryObj.next && !nextUrl.split('next')[0].includes('login')) {
            router.replace({ name: 'Login', query: { next: window.location.href } })
            return
          }
        }
      }
      // 如果没有 module 的 permission 就不允许访问
      if (to.meta && to.meta.moduleName && !UserModule.appModules.find((_: any) => _.name === to.meta.moduleName)) {
        next('/404')
      } else {
        next()
      }
    }
  } else {
    next('/404')
  }
  if (to.name === from.name) {
    // 完成进度条
    NProgress.done()
  }
})

router.afterEach(() => {
  NProgress.done()
})
