/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'office': {
    width: 500,
    height: 500,
    viewBox: '0 0 1024 1024',
    data: '<defs/><path pid="0" d="M788.992 164.843l63.445 103.829a64 64 0 01-18.069 85.93l-3.84 2.475-.363.171 33.472 86.037a64 64 0 01-48.597 86.23l-335.787 58.922v44.587H812.16a64 64 0 0160.096 41.941l21.76 59.286a64 64 0 01-60.075 86.058H288.15a64 64 0 01-63.893-60.245l-.107-4.267.448-59.264a63.456 63.456 0 012.411-16.938L152.533 550.57a64 64 0 0122.144-86.678L701.12 143.531a64 64 0 0187.872 21.312zm23.147 532.181H288.597l-.448 59.285H833.92l-21.76-59.285zm-396.694-64v-33.387l-7.232 1.259-55.658 32.128h62.89zM734.38 198.187L207.957 518.57l53.718 93.034 536.17-309.546-63.466-103.851zm42.688 199.061l-192.278 107.67 219.2-38.443-26.922-69.227z"/>'
  }
})
