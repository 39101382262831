/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'users': {
    width: 430,
    height: 430,
    viewBox: '0 0 1024 1024',
    data: '<defs/><path pid="0" d="M947.2 921.6H384c-42.342 0-76.8-34.458-76.8-76.8 0-2.458.563-60.928 47.309-118.528 26.88-33.075 63.539-59.29 108.953-77.926 54.836-22.528 122.88-33.895 202.138-33.895s147.251 11.418 202.138 33.895c45.414 18.636 82.073 44.85 108.953 77.926 46.797 57.6 47.309 116.07 47.309 118.528 0 42.342-34.458 76.8-76.8 76.8zm-588.8-76.646A25.6 25.6 0 00384 870.4h563.2a25.6 25.6 0 0025.6-25.446c-.051-1.792-1.638-45.824-37.837-88.781-49.869-59.239-143.001-90.522-269.363-90.522s-219.494 31.335-269.363 90.522c-36.199 43.008-37.786 86.989-37.837 88.78zM665.6 563.2c-112.947 0-204.8-91.853-204.8-204.8s91.853-204.8 204.8-204.8 204.8 91.853 204.8 204.8-91.853 204.8-204.8 204.8zm0-358.4c-84.685 0-153.6 68.915-153.6 153.6S580.915 512 665.6 512s153.6-68.915 153.6-153.6-68.915-153.6-153.6-153.6zM230.4 921.6H76.8C34.458 921.6 0 887.142 0 844.8c0-1.894.41-47.462 33.946-92.16 19.353-25.856 45.772-46.285 78.49-60.826 39.116-17.408 87.449-26.214 143.615-26.214 9.165 0 18.227.256 26.983.717a25.6 25.6 0 01-2.714 51.149 465.92 465.92 0 00-24.218-.615c-199.372 0-204.646 121.805-204.8 128.154a25.6 25.6 0 0025.6 25.446h153.6a25.6 25.6 0 010 51.2zM256 614.4c-84.685 0-153.6-68.915-153.6-153.6S171.315 307.2 256 307.2s153.6 68.915 153.6 153.6S340.685 614.4 256 614.4zm0-256c-56.474 0-102.4 45.926-102.4 102.4S199.526 563.2 256 563.2s102.4-45.926 102.4-102.4S312.474 358.4 256 358.4z"/>'
  }
})
