/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'user': {
    width: 430,
    height: 430,
    viewBox: '0 0 1024 1024',
    data: '<defs/><path pid="0" d="M248.384 264.128C248.384 118.272 366.4 0 512 0s263.616 118.272 263.616 264.128S657.6 528.256 512 528.256 248.384 409.984 248.384 264.128zM972.8 1024C961.344 778.944 759.424 595.264 512 595.264S62.656 778.944 51.2 1024h921.6z"/>'
  }
})
