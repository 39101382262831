





































import { defineComponent, ref, computed, onMounted } from '@vue/composition-api'
import { useRouter } from '@u3u/vue-hooks'
import { AppModule } from '@/store/modules/app'
import { UserModule } from '@/store/modules/user'
import Avatars from '@dicebear/avatars'
import SpriteCollection from '@dicebear/avatars-male-sprites'
import Breadcrumb from '@/components/Breadcrumb/index.vue'
import Hamburger from '@/components/Hamburger/index.vue'

export default defineComponent({
  components: {
    Breadcrumb,
    Hamburger,
  },
  setup() {
    const avatars = new Avatars(SpriteCollection)
    const userModule = UserModule
    const { router } = useRouter()
    const signOutLoading = ref(false)

    const sidebar = computed(() => {
      return AppModule.sidebar
    })

    const avatar = () => {
      const svg = avatars.create(userModule.username)
      return encodeURIComponent(svg)
    }

    const toggleSideBar = async () => {
      await AppModule.ToggleSideBar(false)
    }

    const logout = async () => {
      signOutLoading.value = true
      await UserModule.signOut()
      signOutLoading.value = false
      router.push({ name: 'Login', query: { next: window.location.href } })
    }

    const handleCommand = (command: any) => {
      switch (command) {
        case 'logout':
          logout()
          return
      }
    }

    // nav bar list permission
    const navBarRoute = computed(() =>
      UserModule.appModules.map((_: any) => {
        return _.name
      })
    )

    return { signOutLoading, avatars, sidebar, avatar, toggleSideBar, handleCommand, userModule, navBarRoute }
  },
})
