/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'wechat': {
    width: 430.42,
    height: 430,
    viewBox: '0 0 1025 1024',
    data: '<defs/><path pid="0" d="M1016.32 570.88C985.6 460.8 903.68 391.68 775.68 363.52c-28.16-5.12-40.96-12.8-46.08-38.4-2.56-20.48-15.36-38.4-25.6-53.76C634.88 161.28 524.8 102.4 384 94.72c-102.4 2.56-179.2 23.04-243.2 69.12C30.72 243.2-12.8 343.04 7.68 463.36c12.8 66.56 48.64 122.88 115.2 176.64 12.8 10.24 15.36 17.92 10.24 30.72-7.68 20.48-15.36 40.96-20.48 66.56-2.56 7.68 0 20.48 5.12 28.16 5.12 5.12 20.48 0 25.6 0 10.24-10.24 23.04-15.36 38.4-23.04s33.28-17.92 51.2-25.6c7.68-2.56 20.48-5.12 30.72-5.12 53.76 2.56 97.28 5.12 138.24 10.24 5.12 0 12.8 7.68 17.92 12.8C476.16 847.36 606.72 913.92 734.72 896c66.56-10.24 110.08 0 153.6 28.16 5.12 5.12 12.8 5.12 20.48 5.12h15.36v-5.12-7.68c0-7.68 2.56-15.36 0-20.48-20.48-40.96-12.8-69.12 25.6-102.4 64-61.44 89.6-140.8 66.56-222.72zM166.4 691.2c2.56-10.24 5.12-20.48 10.24-30.72 10.24-20.48 5.12-35.84-15.36-51.2-58.88-43.52-92.16-89.6-104.96-148.48-23.04-102.4 12.8-189.44 104.96-256 58.88-43.52 133.12-64 204.8-64 104.96 0 212.48 43.52 276.48 125.44 12.8 17.92 23.04 38.4 35.84 61.44 5.12 7.68 7.68 17.92 12.8 28.16-107.52 7.68-194.56 51.2-248.32 122.88-43.52 56.32-58.88 128-43.52 199.68h-25.6c-23.04 0-43.52 0-61.44-5.12-51.2-12.8-89.6-7.68-125.44 17.92-5.12 2.56-10.24 5.12-17.92 7.68-2.56 0-2.56 2.56-5.12 2.56 0-2.56 2.56-7.68 2.56-10.24zm765.44 43.52c-17.92 23.04-35.84 40.96-51.2 53.76-23.04 17.92-28.16 35.84-17.92 61.44 2.56 2.56 2.56 7.68 2.56 10.24v5.12c-20.48-20.48-43.52-25.6-64-25.6-15.36 0-28.16 2.56-43.52 5.12-7.68 0-15.36 2.56-20.48 2.56-102.4 12.8-189.44-23.04-250.88-99.84-64-79.36-56.32-192 23.04-266.24 53.76-51.2 133.12-79.36 212.48-76.8 79.36 5.12 153.6 38.4 204.8 97.28 56.32 69.12 61.44 161.28 5.12 232.96z"/><path pid="1" d="M204.79999999999998 304.64a1.7 1.7 0 1087.04 0 1.7 1.7 0 10-87.04 0zM455.68 304.64a1.7 1.7 0 1087.04 0 1.7 1.7 0 10-87.04 0zM578.5600000000001 550.4a1.3 1.3 0 1066.56 0 1.3 1.3 0 10-66.56 0zM775.6800000000001 550.4a1.3 1.3 0 1066.56 0 1.3 1.3 0 10-66.56 0z"/>'
  }
})
