/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'role': {
    width: 430,
    height: 430,
    viewBox: '0 0 1024 1024',
    data: '<defs/><path pid="0" d="M512.1 487.9c-61.9 0-112.3-50.4-112.3-112.3s50.4-112.3 112.3-112.3 112.3 50.4 112.3 112.3S574 487.9 512.1 487.9zm0-169.3c-31.5 0-57 25.6-57 57s25.6 57 57 57c31.5 0 57-25.6 57-57s-25.5-57-57-57zM663 760.7H361.2c-28.4 0-54.5-15.2-69.7-40.7-16.2-27-17-59.6-2.2-87.1 41.9-77.7 120-125.9 203.8-125.9h38.1c83.9 0 161.9 48.2 203.8 125.9 14.7 27.5 13.9 60-2.2 87.1-15.3 25.5-41.4 40.7-69.8 40.7zM493.1 562.2c-63.5 0-123 37.1-155.1 96.9-5.6 10.3-5.2 22.2 1 32.5 5.3 8.8 13.4 13.9 22.3 13.9H663c8.8 0 17.2-5.2 22.3-13.8 6.1-10.2 6.5-22.4 1-32.6-32.2-59.7-91.6-96.8-155.1-96.8h-38.1z" _fill="#333"/><path pid="1" d="M512.1 1002c-1.2 0-2.4-.1-3.6-.2-4.4-.6-438.5-61.7-438.5-414.2V181.1c0-12 7.8-22.7 19.3-26.3L503.7 23.3c5.5-1.7 11.2-1.7 16.7 0l414.4 131.5c11.4 3.6 19.3 14.3 19.3 26.3v406.5c0 352.6-434.1 413.6-438.5 414.2-1.1.1-2.3.2-3.5.2zM125.3 201.3v386.3c0 289.1 348 352.7 386.9 358.9 42.2-6.4 386.7-67.3 386.7-358.9V201.3L512.1 78.6 125.3 201.3z" _fill="#333"/>'
  }
})
