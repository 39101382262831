import Vue from 'vue'
import AtLoadingOptions from './AtLoading.vue'

interface IAtLoadingVm extends Vue {
  count?: number,
  show?: () => void
  hide?: () => void
}
export interface IAtLoadingUtil {
  isLoading: boolean,
  loading: () => void,
  loaded: () => void
}

const AtLoadingConstructor = Vue.extend(AtLoadingOptions)

let atLoadingVm: null | IAtLoadingVm = null

function loading() {
  if (!atLoadingVm) {
    atLoadingVm = new AtLoadingConstructor().$mount()
    document.body.appendChild(atLoadingVm.$el)
  }
  if (atLoadingVm.show) {
    atLoadingVm.show()
  }
}

function loaded() {
  if (atLoadingVm) {
    if (atLoadingVm.hide) {
      atLoadingVm.hide()
    }
  }
}

const atLoadingUtil: IAtLoadingUtil = {
  get isLoading() {
    if (atLoadingVm) {
      return !!atLoadingVm.count
    }
    return false
  },
  loading,
  loaded,
}

export default atLoadingUtil
