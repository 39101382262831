/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'contacts': {
    width: 430,
    height: 430,
    viewBox: '0 0 1024 1024',
    data: '<defs/><path pid="0" d="M820.672 3.52H246.08a128 128 0 00-128 128v118.464l-.064.32v4.096h.832c2.048 14.4 13.888 25.6 28.8 25.6s26.88-11.2 28.928-25.6h.384v-1.92c.064-.704.448-1.408.448-2.176s-.384-1.472-.448-2.24V160a96 96 0 0196-96h520.768a96 96 0 0196 96v702.784a96 96 0 01-96 96H272.96a96 96 0 01-96-96V763.52c.064-.768.448-1.472.448-2.24a29.568 29.568 0 10-59.136-1.28h-.192v.96l-.064.32v131.2a128 128 0 00128 128h574.656a128 128 0 00128-128V131.52a128 128 0 00-128-128z"/><path pid="1" d="M85.376 377.6h128a32 32 0 000-64h-128a32 32 0 000 64zm160 290.688a32 32 0 00-32-32h-128a32 32 0 000 64h128a32 32 0 0032-32zM118.08 457.024v104.704a29.632 29.632 0 1059.264 0V456.96a29.632 29.632 0 10-59.264 0zm176.192 286.464s-4.928 35.52 26.112 37.504c13.952.896 25.024-10.432 27.136-23.808h.192c8.64-107.2 91.264-185.344 200.64-185.344 109.44 0 191.744 78.144 200.32 185.344h.512c2.112 13.376 13.248 24.96 27.2 23.808 35.008-3.008 26.048-37.44 26.048-37.44A256.96 256.96 0 00636.8 532.544a160.32 160.32 0 10-176.768 0 256.96 256.96 0 00-165.696 210.944zm145.28-347.648a108.8 108.8 0 11217.6 0 108.8 108.8 0 01-217.6 0z"/>'
  }
})
