/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'form': {
    width: 64,
    height: 64,
    viewBox: '0 0 1024 1024',
    data: '<defs/><path pid="0" d="M942.827 80.337c-11.424-11.407-26.41-17.118-41.377-17.118-14.985 0-29.952 5.71-41.36 17.118L719.393 221.015l-19.441 19.441-18.374 18.376-112.06 112.078-193.52 193.502v84.392h81.156l95.418-95.436 213.398-213.4 3.142-3.142h-.02l9.98-9.977 163.756-163.776c22.87-22.814 22.87-59.887 0-82.736z"/><path pid="1" d="M793.542 367.521l-213.4 213.418-95.416 95.438L473.3 687.8H337V548.265l11.425-11.426 193.519-193.5 112.06-112.08 11.424-11.424H64.02V960.78h740.946V356.117l-8.359 8.358z"/>'
  }
})
