const errorLogStack: any = []

export const getErrorLog = () => {
  const collectErrorLog = (errorObj: any) => {
    if (!errorObj) {
      return
    }

    if (errorObj.config && errorObj.response) {
      const { url, baseURL, method, data } = errorObj.response.config
      const { data: responseData, status, statusText } = errorObj.response
      // 不上报接口白名单
      const whiteList = ['/api/v1/user/set_password', '/api/v1/users/profile/public-key']

      const errorResponseBody = {
        errorType: '服务端响应异常',
        requestUrl: `${baseURL}${url}`,
        requestMethod: method,
        requestBody: !whiteList.includes(`${baseURL}${url}`) && data ? JSON.parse(data) : '无/不上报',
        responseData,
        httpStatus: status,
        message: statusText,
      }
      errorLogStack.push(errorResponseBody)
    }

    if (errorObj.config && errorObj.response === undefined) {
      const { url, baseURL, method } = errorObj.config
      const errorResponseBody = {
        errorType: '客户端网络不佳或接口请求超时或请求跨域',
        requestUrl: `${baseURL}${url}`,
        requestMethod: method,
        message: errorObj.message,
      }
      errorLogStack.push(errorResponseBody)
    }

    if (!errorObj.config && !errorObj.response) {
      const errorResponseBody = {
        errorType: '页面前端 js 逻辑错误',
        pageUrl: `${window.location.href}`,
        message: errorObj.message,
      }
      errorLogStack.push(errorResponseBody)
    }
  }

  const clearErrorLogStack = () => {
    errorLogStack.length = 0
  }

  return { errorLogStack, collectErrorLog, clearErrorLogStack }
}
