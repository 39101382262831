/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'tree': {
    width: 64,
    height: 64,
    viewBox: '0 0 1024 1024',
    data: '<defs/><path pid="0" d="M1013.703 693.345c6.865 6.865 10.297 14.874 10.297 24.027v205.944c0 9.916-3.432 18.115-10.297 24.599-6.865 6.483-15.255 9.725-25.171 9.725H782.588c-9.153 0-17.162-3.242-24.027-9.725-6.865-6.483-10.297-14.683-10.297-24.599V717.372c0-6.102 1.526-11.823 4.577-17.162s7.246-9.534 12.586-12.586 11.06-4.577 17.162-4.577h77.801V546.896c0-4.577-1.144-8.772-3.432-12.586s-5.339-6.865-9.153-9.153-8.009-3.432-12.585-3.432H543.464v161.323h77.801c9.153 0 17.162 3.432 24.027 10.297s10.297 14.874 10.297 24.027v205.944c0 6.102-1.526 11.823-4.577 17.162s-7.246 9.534-12.585 12.585-11.06 4.577-17.162 4.577H415.321c-6.102 0-11.823-1.526-17.162-4.577s-9.725-7.246-13.158-12.585-5.149-11.06-5.149-17.162V717.372c0-9.153 3.432-17.162 10.297-24.027s15.255-10.297 25.171-10.297h76.657V521.725H188.782c-7.628 0-13.92 2.479-18.878 7.437s-7.437 10.869-7.437 17.734v136.152h77.801c9.916 0 18.115 3.432 24.599 10.297s9.725 14.874 9.725 24.027v205.944c0 9.916-3.242 18.115-9.725 24.599-6.483 6.483-14.683 9.725-24.599 9.725H34.324c-3.814 0-7.437-.572-10.869-1.716-3.432-1.144-6.483-2.67-9.153-4.577-2.67-1.907-5.149-4.386-7.437-7.437-2.288-3.051-4.004-6.293-5.149-9.725C.572 930.753 0 927.13 0 923.316V717.372c0-3.051.381-6.102 1.144-9.153s1.907-5.721 3.432-8.009 3.432-4.577 5.721-6.865 4.577-4.195 6.865-5.721 4.958-2.67 8.009-3.432 6.102-1.144 9.153-1.144h77.801V495.41c0-6.865 2.479-12.776 7.437-17.734s10.869-7.437 17.734-7.437h354.682V342.096h-76.657c-9.916 0-18.306-3.432-25.171-10.297s-10.297-14.874-10.297-24.027V101.828c0-9.916 3.432-18.306 10.297-25.171s15.255-10.297 25.171-10.297h205.944c6.102 0 11.823 1.716 17.162 5.149 5.339 3.432 9.534 7.818 12.585 13.158 3.051 5.339 4.577 11.06 4.577 17.162v205.944c0 9.153-3.432 17.162-10.297 24.027s-14.874 10.297-24.027 10.297h-77.801V470.24H885.56c7.628 0 13.92 2.479 18.878 7.437s7.437 10.869 7.437 17.734v187.638h76.657c9.916-.001 18.306 3.431 25.171 10.296z"/>'
  }
})
