/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'employee_email': {
    width: 645.996,
    height: 500,
    viewBox: '0 0 1323 1024',
    data: '<defs/><path pid="0" d="M78.81 303.267v-145.72a78.768 78.768 0 0178.768-78.77h901.897a82.707 82.707 0 0183.494 78.77v148.871L607.345 630.156zm1102.756 523.81a35.446 35.446 0 00-37.021 37.809 83.494 83.494 0 01-83.495 83.494H157.578a78.768 78.768 0 01-78.769-78.768V389.125l513.57 315.073a40.172 40.172 0 0018.904 0c7.09 0 14.966 0 18.117-3.938l571.07-345.793 6.302-6.302a11.815 11.815 0 000-4.726 11.815 11.815 0 000-8.664V157.546a142.57 142.57 0 00-36.234-110.276A157.537 157.537 0 001056.324.01H157.578A157.537 157.537 0 00.04 157.545v708.915a151.235 151.235 0 0045.686 111.851 157.537 157.537 0 00111.85 45.686h901.898a157.537 157.537 0 00157.537-157.537 35.446 35.446 0 00-35.446-39.384z" _fill="#2c2c2c"/><path pid="1" d="M1024.03 570.292a31.507 31.507 0 0031.506 31.507h236.305a31.507 31.507 0 000-63.014h-236.305a31.507 31.507 0 00-31.507 31.507m267.812 96.097h-236.305a31.507 31.507 0 00-29.931 31.508 31.507 31.507 0 0029.931 31.507h236.305a31.507 31.507 0 0029.932-31.507 30.72 30.72 0 00-29.932-31.508" _fill="#2c2c2c"/>'
  }
})
