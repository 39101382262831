/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'token': {
    width: 430,
    height: 430,
    viewBox: '0 0 1024 1024',
    data: '<defs/><path pid="0" d="M512 1024C229.376 1024 0 794.624 0 512S229.376 0 512 0s512 229.376 512 512-229.376 512-512 512m286.72-614.4h-71.68v-92.16s0-215.04-215.04-215.04-215.04 215.04-215.04 215.04v92.16h-71.68v409.6h573.44V409.6zM512 163.84c143.36 0 153.6 129.024 153.6 153.6v92.16H358.4v-92.16c0-24.576 10.24-153.6 153.6-153.6m0 368.64c34.816 0 61.44 26.624 61.44 61.44 0 22.528-12.288 43.008-30.72 53.248v108.544h-61.44V647.168c-18.432-10.24-30.72-30.72-30.72-53.248 0-34.816 26.624-61.44 61.44-61.44"/>'
  }
})
