/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'application': {
    width: 300,
    height: 300,
    viewBox: '0 0 1024 1024',
    data: '<defs/><path pid="0" d="M406.642 141.835c11.09 0 20.113 9.022 20.113 20.113v244.204c0 11.09-9.022 20.113-20.113 20.113H162.438c-11.09 0-20.113-9.023-20.113-20.113V161.949c0-11.09 9.023-20.113 20.113-20.113h244.204m0-46.439H162.438c-36.755 0-66.55 29.797-66.55 66.55v244.205c0 36.755 29.796 66.55 66.55 66.55h244.204c36.756 0 66.55-29.796 66.55-66.55V161.949c.002-36.755-29.794-66.552-66.55-66.552zm0 502.575c11.09 0 20.113 9.022 20.113 20.113v244.204c0 11.09-9.022 20.113-20.113 20.113H162.438c-11.09 0-20.113-9.023-20.113-20.113V618.086c0-11.09 9.023-20.113 20.113-20.113h244.204m0-46.439H162.438c-36.755 0-66.55 29.796-66.55 66.55V862.29c0 36.755 29.796 66.55 66.55 66.55h244.204c36.756 0 66.55-29.796 66.55-66.55V618.086c.002-36.756-29.794-66.552-66.55-66.552zm454.09 46.438c11.09 0 20.112 9.022 20.112 20.113v244.204c0 11.09-9.022 20.113-20.113 20.113H616.527c-11.09 0-20.113-9.023-20.113-20.113V618.086c0-11.09 9.023-20.113 20.113-20.113h244.204m0-46.439H616.527c-36.755 0-66.55 29.796-66.55 66.55V862.29c0 36.755 29.796 66.55 66.55 66.55h244.204c36.756 0 66.55-29.796 66.55-66.55V618.086c.002-36.756-29.794-66.552-66.55-66.552zM738.63 110.27c3.111 0 9.097.765 14.223 5.89l153.666 153.668c7.842 7.842 7.842 20.602 0 28.444L752.852 451.94c-5.126 5.125-11.112 5.891-14.223 5.891s-9.096-.764-14.223-5.891L570.74 298.273c-7.842-7.842-7.842-20.602 0-28.444L724.407 116.16c5.127-5.126 11.111-5.89 14.223-5.89m0-46.439c-17.032 0-34.064 6.498-47.059 19.493L537.904 236.992c-25.99 25.989-25.99 68.127 0 94.117l153.667 153.667c12.995 12.995 30.027 19.493 47.059 19.493s34.063-6.498 47.059-19.493l153.666-153.667c25.99-25.99 25.99-68.127 0-94.117L785.69 83.325c-12.996-12.995-30.028-19.493-47.06-19.493z"/>'
  }
})
