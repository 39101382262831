/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'approve': {
    width: 430,
    height: 430,
    viewBox: '0 0 1024 1024',
    data: '<defs/><path pid="0" d="M874.2 257.1l-320-181.9C541.1 67.7 526.5 64 512 64c-14.5 0-29.1 3.7-42.2 11.1L149.8 257c-26.7 15.2-43.2 43.5-43.2 74.2v361.5c0 30.7 16.5 59 43.2 74.2l320 181.9c13.1 7.4 27.6 11.1 42.2 11.1 14.5 0 29.1-3.7 42.2-11.1l320-181.9c26.7-15.2 43.2-43.5 43.2-74.2V331.2c-.1-30.7-16.5-59-43.2-74.1zm.5 435.7c0 15.3-8.3 29.5-21.6 37.1l-320 181.9c-6.4 3.6-13.7 5.6-21.1 5.6s-14.7-1.9-21.1-5.6l-320-181.9c-13.3-7.6-21.6-21.8-21.6-37.1V331.2c0-15.3 8.3-29.5 21.6-37.1l320-181.9c6.4-3.6 13.7-5.6 21.1-5.6s14.7 1.9 21.1 5.6l320 181.9c13.3 7.6 21.6 21.8 21.6 37.1v361.6z"/><path pid="1" d="M603.6 631.4L498.1 736.9l-45.2-45.2c-8.3-8.3-21.8-8.3-30.2 0-8.3 8.3-8.3 21.8 0 30.2l59.8 59.8c.2.2.2.4.4.5 4.2 4.2 9.7 6.3 15.2 6.2 5.5 0 11-2 15.2-6.2.2-.2.2-.4.4-.5l120.1-120.1c8.3-8.3 8.3-21.8 0-30.2-8.4-8.3-21.9-8.3-30.2 0z"/><path pid="2" d="M682.7 532.5h-55l-48.2-83.4c36.3-22.6 60.5-62.7 60.5-108.6 0-70.7-57.3-128-128-128s-128 57.3-128 128c0 45.9 24.2 86 60.5 108.6l-48.2 83.4h-55c-23.6 0-42.7 19.1-42.7 42.7v128c0 11.8 9.6 21.3 21.3 21.3s21.3-9.6 21.3-21.3V596.5c0-11.8 9.6-21.3 21.3-21.3h298.7c11.8 0 21.3 9.6 21.3 21.3v106.7c0 11.8 9.6 21.3 21.3 21.3s21.3-9.6 21.3-21.3v-128c.2-23.6-18.9-42.7-42.4-42.7zm-256-192c0-47.1 38.3-85.3 85.3-85.3 47.1 0 85.3 38.3 85.3 85.3S559 425.8 512 425.8c-47.1.1-85.3-38.2-85.3-85.3zm18.9 192l38.7-67.1c8.9 2 18.2 3.1 27.7 3.1s18.8-1.1 27.7-3.1l38.7 67.1H445.6z"/>'
  }
})
