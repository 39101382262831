/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'finance': {
    width: 500,
    height: 500,
    viewBox: '0 0 1024 1024',
    data: '<defs/><path pid="0" d="M849.823 315.545H176.769c-60.418 0-109.566 49.149-109.566 109.567V839.9c0 60.418 49.148 109.566 109.566 109.566h673.054c60.403 0 109.566-49.148 109.566-109.566V425.11c0-60.433-49.163-109.566-109.566-109.566zm46.956 524.356c0 25.889-21.067 46.957-46.956 46.957H176.769c-25.889 0-46.957-21.069-46.957-46.957V425.11c0-25.89 21.068-46.956 46.957-46.956h673.054c25.89 0 46.956 21.067 46.956 46.957V839.9zM658.973 606.179c13.524 0 24.528-10.989 24.528-24.528 0-13.54-11.004-24.543-24.528-24.543h-91.801l93.618-93.601c9.578-9.594 9.578-25.122 0-34.702-9.58-9.594-25.107-9.594-34.702 0L514.626 540.298 403.134 428.805c-9.58-9.594-25.106-9.594-34.702 0-9.578 9.564-9.578 25.108 0 34.702l93.618 93.601h-94.446c-13.556 0-24.528 11.003-24.528 24.543s10.973 24.528 24.528 24.528h121.149v54.063h-121.15c-13.555 0-24.527 10.989-24.527 24.544 0 13.54 10.973 24.51 24.528 24.51h121.149V824.89c0 13.54 10.973 24.528 24.528 24.528 13.54 0 24.526-10.988 24.526-24.528V709.297h121.15c13.522 0 24.527-10.972 24.527-24.51 0-13.556-11.004-24.545-24.528-24.545H537.807V606.18h121.166zM179.697 276.664H846.88c17.28 0 31.304-14.023 31.304-31.305 0-17.295-14.024-31.305-31.304-31.305H179.697c-17.28 0-31.305 14.009-31.305 31.305 0 17.282 14.025 31.305 31.305 31.305zm83.16-100.425h500.877c17.281 0 31.305-14.025 31.305-31.304 0-17.296-14.023-31.304-31.305-31.304H262.857c-17.28 0-31.305 14.009-31.305 31.304 0 17.28 14.01 31.304 31.306 31.304z"/>'
  }
})
