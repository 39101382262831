/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'resourcesManagement': {
    width: 400,
    height: 400,
    viewBox: '0 0 1024 1024',
    data: '<defs/><path pid="0" d="M512.138.002a506.464 506.464 0 00-222.615 50.79 25.6 25.6 0 1022.323 46.08 456.288 456.288 0 01200.292-45.67 460.59 460.59 0 11-400.993 233.673 25.6 25.6 0 00-44.646-25.19A511.993 511.993 0 10512.138.002z"/><path pid="1" d="M132.44299999999998 161.383a51.2 51.2 0 10102.4 0 51.2 51.2 0 10-102.4 0zM661.845 266.239a25.395 25.395 0 00-11.674-34.201 312.316 312.316 0 10-172.439 590.635 329.519 329.519 0 0034.61 1.843A312.52 312.52 0 00820.769 463.46a25.6 25.6 0 00-50.585 7.577 260.707 260.707 0 11-143.358-193.738 25.395 25.395 0 0035.02-11.06z"/><path pid="2" d="M733.32 393.213a51.2 51.2 0 10-45.261-56.524 51.2 51.2 0 0045.26 56.524zM460.93800000000005 511.996a51.2 51.2 0 10102.4 0 51.2 51.2 0 10-102.4 0z"/>'
  }
})
