import { VuexModule, Module, MutationAction, getModule } from 'vuex-module-decorators'
import { axios } from '@/lib/axios'
import { IAdminUser, IAuthority, IRole, IModule, IHrbp, IManager } from '@/interfaces/admin-user'
import store from '@/store'
import Vue from 'vue'

export interface IAdminUserState {
  id: number
  username: string
  fullname: string
  email: string
  title: string
  mobile: string
  status: string
  // tslint:disable-next-line
  role_info: string[]
  // tslint:disable-next-line
  dept_info: any[]
  appModules: IModule[]
  authorities: any[]
  // tslint:disable-next-line
  is_admin: boolean
  // tslint:disable-next-line
  is_hr: boolean
  avatar: string
  given_name: string
  family_name: string
  chinese_name: string
  nickname: string
  office: string
  // tslint:disable-next-line
  user_id: string
  // tslint:disable-next-line
  onboard_date: string
  hrbp: IHrbp[]
  manager: IManager[]
  // tslint:disable-next-line
  main_email: string
  // tslint:disable-next-line
  employee_number: string
  biz_line_info: any[]
  display_name: any
  is_kp_user: boolean
}

const defaultState = {
  id: 0,
  username: '',
  email: '',
  title: '',
  mobile: '',
  status: '',
  authorities: [],
  appModules: [],
  role_info: [],
  dept_info: [],
  is_admin: false,
  is_hr: false,
  avatar: '',
  given_name: '',
  family_name: '',
  chinese_name: '',
  nickname: '',
  office: '',
  user_id: '',
  onboard_date: '',
  hrbp: [],
  manager: [],
  main_email: '',
  employee_number: '',
  biz_line_info: [],
  display_name: '',
  is_kp_user: false,
  menu_list: [],
}

@Module({ dynamic: true, store, name: 'user' })
class User extends VuexModule implements IAdminUserState {
  id = 0
  username = ''
  fullname = ''
  title = ''
  email = ''
  mobile = ''
  status = ''
  // tslint:disable-next-line
  role_info = []
  // tslint:disable-next-line
  dept_info = []
  appModules = []
  authorities = []
  // tslint:disable-next-line
  is_admin = false
  // tslint:disable-next-line
  is_hr = false
  avatar = ''
  // tslint:disable-next-line
  given_name = ''
  // tslint:disable-next-line
  family_name = ''
  // tslint:disable-next-line
  chinese_name = ''
  nickname = ''
  office = ''
  // tslint:disable-next-line
  user_id = ''
  // tslint:disable-next-line
  onboard_date = ''
  hrbp = []
  manager = []
  // tslint:disable-next-line
  main_email = ''
  // tslint:disable-next-line
  employee_number = ''
  biz_line_info = []
  display_name = ''
  is_kp_user = false
  menu_list = []

  @MutationAction({
    rawError: true,
    mutate: [
      'id',
      'username',
      'fullname',
      'title',
      'email',
      'mobile',
      'status',
      'role_info',
      'dept_info',
      'appModules',
      'is_admin',
      'is_hr',
      'avatar',
      'given_name',
      'family_name',
      'chinese_name',
      'nickname',
      'office',
      'user_id',
      'onboard_date',
      'hrbp',
      'manager',
      'main_email',
      'employee_number',
      'biz_line_info',
      'display_name',
      'is_kp_user',
      'menu_list',
    ],
  })
  async fetchUserInfo() {
    const { data } = await axios.get('/login_user')
    return Object.assign({}, defaultState, data.data, { appModules: data.data.menu_list || [] })
  }
  @MutationAction({
    rawError: true,
    mutate: [
      'id',
      'username',
      'title',
      'email',
      'mobile',
      'status',
      'role_info',
      'dept_info',
      'appModules',
      'authorities',
      'is_admin',
      'is_hr',
      'avatar',
      'given_name',
      'family_name',
      'chinese_name',
      'nickname',
      'office',
      'user_id',
      'onboard_date',
      'hrbp',
      'manager',
      'main_email',
      'employee_number',
      'biz_line_info',
      'display_name',
      'is_kp_user',
      'menu_list',
    ],
  })
  async signOut() {
    try {
      await axios.get('/logout')
    } catch (error) {
      // console.log(error)
    }
    return Object.assign({}, defaultState)
  }
}

export const UserModule = getModule(User)
