import Vue from 'vue'
import { CreateElement, RenderContext, VNode } from 'vue'
import { UserModule } from '@/store/modules/user'

export default Vue.extend({
  functional: true,
  props: {
    moduleName: {
      type: String,
      required: true,
    },
    api: {
      /** apiname array */
      type: String,
      required: true,
    },
    tag: {
      type: String,
      required: false,
      default: 'div',
    },
  },
  render(h: CreateElement, context: RenderContext): VNode {
    const { moduleName } = context.props
    if (UserModule.appModules.find((_: any) => _.name === moduleName)) {
      return h(context.props.tag, context.data, context.children)
    }
    return h(context.props.tag)
  },
})
