/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'template': {
    width: 430,
    height: 430,
    viewBox: '0 0 1024 1024',
    data: '<defs/><path pid="0" d="M672 704a128 128 0 01124.096 96.384L800 800h128a32 32 0 110 64H800l-3.968-.32a128 128 0 01-248.064 0L544 864H96a32 32 0 110-64h448l3.904.384A128 128 0 01672 704zm0 64a64 64 0 100 128 64 64 0 000-128zM352 384a128 128 0 01124.096 96.384L480 480h448a32 32 0 015.76 63.488L928 544H480l-3.968-.32a128 128 0 01-248.064 0L224 544H96a32 32 0 01-5.76-63.488L96 480h128l3.904.384A128 128 0 01352 384zm0 64a64 64 0 100 128 64 64 0 000-128zM672 64a128 128 0 01124.096 96.384L800 160h128a32 32 0 015.76 63.488L928 224H800l-3.968-.32a128 128 0 01-248.064 0L544 224H96a32 32 0 01-5.76-63.488L96 160h448l3.904.384A128 128 0 01672 64zm0 64a64 64 0 100 128 64 64 0 000-128z"/>'
  }
})
