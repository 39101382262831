/* tslint:disable */
require('./add-user')
require('./admin')
require('./application')
require('./approvals')
require('./approve')
require('./business')
require('./common_email')
require('./company-entity')
require('./configuration')
require('./contacts')
require('./course-learning')
require('./dashboard')
require('./delete-user')
require('./department')
require('./email')
require('./employee_email')
require('./employee-training')
require('./example')
require('./eye')
require('./finance')
require('./financial')
require('./form')
require('./group_email')
require('./link')
require('./loan')
require('./logs')
require('./market')
require('./my-approval')
require('./my-training')
require('./nested')
require('./offboarding')
require('./office')
require('./organization-chart')
require('./organization')
require('./outsourced-member')
require('./password')
require('./permission')
require('./pre-add-user')
require('./question-bank')
require('./radar')
require('./relationship')
require('./resources')
require('./resourcesManagement')
require('./role')
require('./search')
require('./security')
require('./skills')
require('./table')
require('./team')
require('./template')
require('./test-paper')
require('./title')
require('./token')
require('./tool')
require('./tree')
require('./user-audit')
require('./user-center')
require('./user')
require('./users')
require('./wechat')
require('./workflow')
