/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'permission': {
    width: 430,
    height: 430,
    viewBox: '0 0 1024 1024',
    data: '<defs/><path pid="0" d="M915.2 131.2L571.2 16c-33.6-9.6-84.8-9.6-118.4 0l-344 115.2C65.6 144 32 187.2 32 228.8v460.8c0 64 30.4 126.4 108.8 166.4l308.8 147.2c35.2 17.6 89.6 17.6 124.8 0L915.2 856C992 825.6 992 753.6 992 689.6V228.8c0-41.6-22.4-83.2-76.8-97.6zm-19.2 664L532.8 953.6c-9.6 4.8-32 4.8-43.2 0L160 795.2c-32-16-60.8-59.2-60.8-94.4V211.2c0-4.8 8-14.4 12.8-16L480 73.6c16-4.8 48-4.8 64 0l368 121.6c6.4 1.6 12.8 11.2 12.8 16v489.6c0 33.6 3.2 78.4-28.8 94.4zm-209.6-400c0-96-78.4-174.4-174.4-174.4s-174.4 78.4-174.4 174.4C337.6 480 400 552 480 566.4v264h60.8V699.2h137.6v-62.4H540.8v-70.4c83.2-16 145.6-86.4 145.6-171.2zM512 505.6c-62.4 0-112-49.6-112-112s49.6-112 112-112 112 49.6 112 112-49.6 112-112 112z"/>'
  }
})
