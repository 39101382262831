















import { defineComponent, onMounted, ref } from '@vue/composition-api'
// tslint:disable-next-line
const config = require('../package.json')
import { AppModule } from '@/store/modules/app'
import CaptureErrorUtil from '@/views/common/CaptureErrorUtil.vue'
import { registerMicroApps, start } from 'qiankun'

// 创建版本号进程
function createWorker(f: () => void) {
  const blob = new Blob(['(' + f.toString() + ')()'])
  const url = window.URL.createObjectURL(blob)
  const worker = new Worker(url)
  return worker
}

export default defineComponent({
  components: {
    CaptureErrorUtil,
  },
  setup() {
    const isShowPopup = ref(false)

    const refreshHandler = () => {
      window.location.reload()
      isShowPopup.value = true
    }

    // 轮询监听版本号
    const pollingVersion = () => {
      const worker = createWorker(() => {
        let oldVersion = ''
        const temp: Worker = self as any
        temp.onmessage = (event: any) => {
          oldVersion = event.data['acenter-version']
        }

        setInterval(() => {
          fetch(`${location.origin}/version.json?${+new Date()}`)
            .then((res) => {
              return res.json()
            })
            .then((versionJson) => {
              if (oldVersion !== versionJson.version) {
                temp.postMessage({ refreshPageVersion: `${versionJson.version}`, isRefresh: true })
              }
            })
        }, 30000)
      })

      worker.postMessage({ 'acenter-version': config.version })
      worker.onmessage = (event: any) => {
        AppModule.SetLatestVersion(event.data.refreshPageVersion)
        AppModule.SetIsRefresh(event.data.isRefresh)
      }
    }

    onMounted(async () => {
      console.log('environment', process.env.VUE_APP_SUB_APP_ENTRY)
      registerMicroApps([
        {
          name: 'my-employee-training',
          entry: `${process.env.VUE_APP_SUB_APP_ENTRY}`,
          container: '#qiankunRouterView',
          activeRule: [
            '/my-employee-training',
            '/my-employee-training-certificate/:id',
            '/my-employee-training-exam-do',
            '/my-employee-training-course-do',
            '/employee-training/question-bank',
            '/employee-training/examination-paper',
            '/employee-training/course-learning',
            '/employee-training/upload-members-history',
          ],
        },
      ])
      pollingVersion()
    })

    return { AppModule, isShowPopup, refreshHandler }
  },
})
