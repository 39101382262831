/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'resources': {
    width: 200,
    height: 200,
    viewBox: '0 0 1024 1024',
    data: '<path pid="0" d="M170.822 414.115l-14.15-6.703a90.298 90.298 0 010-163.188L496.593 83.177a90.298 90.298 0 0177.359 0l339.921 161.047a90.298 90.298 0 010 163.188l-25.925 12.335 15.267 7.447c44.59 21.877 63.255 75.916 42.542 121.111-8.75 19.084-23.738 34.63-42.542 43.8l-28.812 14.15 28.393 13.498a90.484 90.484 0 010 163.235L562.316 945.43a90.077 90.077 0 01-77.359 0l-340.48-162.443a90.484 90.484 0 010-163.189l28.393-13.544-28.858-14.15c-44.544-21.83-63.21-75.87-42.496-121.065 8.75-19.084 23.738-34.63 42.542-43.8l26.717-13.125zm107.52 50.967l-90.997 44.59 336.291 164.772 336.291-164.771-79.592-39.005-206.383 97.792a90.298 90.298 0 01-77.359 0L278.342 465.082zM767.767 658.48L562.735 758.924a88.75 88.75 0 01-78.197 0L279.505 658.479l-89.832 42.914L523.636 860.72l334.01-159.326-89.879-42.914zm-565.946-332.66l333.452 157.928 333.451-157.929L535.273 167.89 201.82 325.82z"/>'
  }
})
