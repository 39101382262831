




import { Vue, Component, Prop } from 'vue-property-decorator'

const maxTimeoutTime = 10000

@Component
export default class AtLoading extends Vue {
  count: number = 0
  timer: any = null

  show() {
    this.count++
    clearTimeout(this.timer)
    this.timer = setTimeout(() => {
      this.count = 0
    }, maxTimeoutTime)
  }
  hide() {
    if (this.count > 0) {
      this.count--
    }
    if (this.count === 0) {
      clearTimeout(this.timer)
    }
  }
}
