import { extend } from 'vee-validate'
import { required, email, numeric, min, max, image, between, length, min_value } from 'vee-validate/dist/rules'
// tslint:disable-next-line
const phoneNumber = require('awesome-phonenumber')

extend('required', {
  ...required,
  message: '{_field_} is required',
})

extend('alphaNum', {
  validate: (value) => {
    const re = /^[0-9a-zA-Z]*$/
    if (!re.test(value)) {
      return false
    } else {
      return true
    }
  },
  message: '{_field_} should be nubmer or alpha',
})

extend('image', image)

extend('email', email)

extend('min', {
  ...min,
  message: `The {_field_} must be more than 3 characters`,
})

extend('max', {
  ...max,
  message: `The {_field_} may not be greater than {length} characters`,
})

extend('min_value', {
  ...min_value,
  message: `The {_field_} field must be larger then {min}.`,
})

extend('between', {
  ...between,
  message: `The {_field_} field must be between {min} and {max}.`,
})

extend('numeric', numeric)

extend('url', {
  validate(value: string | null | undefined): boolean {
    if (value) {
      return /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi.test(
        value
      )
    }

    return false
  },
  message: 'This {_field_} must be a valid URL',
})

extend('confirmed', {
  params: ['originalValue'],
  validate: (value, { originalValue }: any) => originalValue === value,
  message: `These account numbers don't match.`,
})

extend('length', {
  params: ['length'],
  // tslint:disable-next-line
  validate: (value, { length }) => {
    const valueLength = value && value.length
    return valueLength === +length
  },
  message: '{_field_} length should be {length}',
})

extend('banknumber', {
  params: ['acceptableLengthList'],
  validate(value, { acceptableLengthList }: any) {
    if (!acceptableLengthList[0]) {
      return true
    }
    return acceptableLengthList.some((val: any) => `${val}` === `${value.length}`)
  },
  message: (field, { acceptableLengthList }: any) => {
    return `Account number's length must be ${acceptableLengthList.join(' or ')}`
  },
})

extend('decimal', {
  params: ['decimalValue'],
  validate(value, { decimalValue, separator = '.' }: any = {}) {
    const regex = new RegExp(`^[-+]?\\d*(\\${separator}\\d{0,${decimalValue}})?$`)
    return {
      valid: regex.test(value),
    }
  },
  message: (field, { decimalValue }: any) => {
    return `The ${field} field must be numeric and may contain ${decimalValue} decimal points.`
  },
})

/**
 * 密码
 * 1.至少包含数字、大写字母、小写字母, 特殊字符 ~!@#$%^&*_-+=`(){}[]:;"'<>,.?/
 * 2. 由字母、数字、字符组成
 * 3. 不能有空格
 * 4. 8-16个字符
 * 5. 不能是中文
 */
extend('password', {
  validate(value) {
    const regex = new RegExp(
      `^.*(?=.{10,})(?=.*[~!@#$%^&*\\-_+=\`/'?:;.()\\[\\]{}<>,"]{1,})(?=.*[0-9]{1,})(?=.*[A-Z]{1,})(?=.*[a-z]{1,}).*$`
    )
    const acceptSpChars = [
      '~',
      '!',
      '@',
      '#',
      '$',
      '%',
      '^',
      '&',
      '*',
      '-',
      '_',
      '+',
      '=',
      '`',
      '(',
      ')',
      '{',
      '}',
      '[',
      ']',
      ':',
      ';',
      '"',
      `'`,
      '<',
      '>',
      ',',
      '.',
      '?',
      '/',
    ]
    const unAcceptCount = value
      .replace(/[0-9]?[A-Z]?[a-z]?/g, '')
      .split('')
      .map((_: any) => acceptSpChars.includes(_))
      .filter((_: any) => _ === false).length
    const result = regex.test(value) && unAcceptCount <= 0
    return {
      valid: result,
    }
  },
  message: (field) => {
    return `The ${field} is invalid.`
  },
})

// 校验ssh-rsa key
extend('sshRsa', {
  validate(value) {
    const regex = new RegExp('^ssh-rsa AAAA[0-9A-Za-z+/]+[=]{0,3}( [^@]+@[^@]+)?$')
    return {
      valid: regex.test(value),
    }
  },
  message: (field) => {
    return `The ${field} is invalid.`
  },
})

// 校验givenName, middleName, familyName
// 规则: 只允许输入字母
extend('givenAndFamilyName', {
  validate(value) {
    const regex = new RegExp('^[A-Za-z ]+$')
    return {
      valid: regex.test(value),
    }
  },
  message: (field) => {
    return `The ${field} can only contain letters.`
  },
})

// 校验是否是json
extend('isJsonObject', {
  validate(value) {
    let result = false
    try {
      if (value !== 'null' && typeof JSON.parse(value) === 'object') {
        return (result = true)
      }
    } catch (e) {
      return (result = false)
    }

    return {
      valid: result,
    }
  },
  message: (field) => {
    return `The ${field} must be in json format.`
  },
})

extend('secret', {
  validate: (value) => value === 'example',
  message: 'This is not the magic word',
})

// 入职邮箱
/**
 * 规则:
 * 1. 20位以内
 * 2. 格式只能包含: 字母, ‘.’, 数字
 * 3. 开头只能为字母
 * 4. 最多只能包含三个点
 * 5. 数字只能出现在最后一个点后面
 *
 * 例如: meixing.gu、meixing.gu.mary、 meixing.gu1 或 meixing.gu.1
 */
extend('onboardEmail', {
  validate(value) {
    const regex = new RegExp('^(?![0-9][a-z]*)([a-z]+.){0,4}[a-z0-9]+$')
    return {
      valid: regex.test(value),
    }
  },
  message: (field) => {
    return `${field} is invalid`
  },
})

/**
 * 校验国际手机号
 * phoneNumber
 */
extend('mobile', {
  params: ['country'],
  validate: (value, { country }: any) => {
    const countrySort = country.split('&')[0]
    const pn = new phoneNumber(value, countrySort)
    return pn.isMobile()
  },
  message: 'Mobile is not valid.',
})

export default {
  required,
  email,
  numeric,
  min,
  max,
  image,
  between,
  length,
  min_value,
}
