/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'organization-chart': {
    width: 430,
    height: 430,
    viewBox: '0 0 1024 1024',
    data: '<defs/><path pid="0" d="M236.9 308.1c7.4 0 14.4 2 20.4 5.5v-92.4c-6 3.5-12.9 5.4-20.4 5.4h-40.7c-7.4 0-14.4-2-20.4-5.4v92.4c6-3.5 12.9-5.5 20.4-5.5h40.7zm0-162.9c7.4 0 14.4 2 20.4 5.5v-25.8c0-22.5-18.2-40.7-40.7-40.7-22.5 0-40.7 18.3-40.7 40.7v25.8c6-3.5 12.9-5.5 20.4-5.5h40.6zM557 450.5h236.1c41.7 0 75.6-34.4 75.6-76.8V140.8c0-42.4-33.8-76.8-75.6-76.8H557c-41.7 0-75.5 34.4-75.5 76.8v106.6H394c-41.7 0-75.5 34.4-75.5 76.7v167.4h-61.2V384.2c-6 3.5-12.9 5.5-20.4 5.5h-40.7c-7.4 0-14.4-2-20.4-5.5V660c6-3.5 12.9-5.5 20.4-5.5h40.7c7.4 0 14.4 2 20.4 5.5v-87h61.2v147c0 42.4 33.8 76.8 75.5 76.8h87.4v65.8c0 42.4 33.8 76.8 75.5 76.8H793c41.7 0 75.6-34.4 75.6-76.8v-233c0-42.4-33.8-76.7-75.6-76.7H557c-41.7 0-75.5 34.4-75.5 76.7V720h-49.6c-20.9 0-37.8-17.2-37.8-38.4V362.5c0-21.2 16.9-38.4 37.8-38.4h49.6v90.4c-.1 42.3 33.8 36 75.5 36zm0-271.4c0-21.2 16.9-38.4 37.8-38.4h119c-5.3 6.9-8.4 15.5-8.4 24.8v20.3c0 22.5 18.2 40.7 40.7 40.7h20.4c10.2 0 19.4-3.7 26.6-9.9v118.6c0 21.2-16.9 38.4-37.8 38.4H594.8c-20.9 0-37.8-17.2-37.8-38.4V179.1zm0 488.9c0-21.1 16.9-38.4 37.8-38.4h119.1c-5.3 6.9-8.5 15.5-8.5 24.8v20.4c0 22.5 18.2 40.7 40.7 40.7h20.4c10.2 0 19.4-3.7 26.6-9.9v118.5c0 21.2-16.9 38.4-37.8 38.4H594.8c-20.9 0-37.8-17.2-37.8-38.4V668zm-360.9 68c-7.4 0-14.4-2-20.4-5.5v92.4c6-3.5 12.9-5.5 20.4-5.5h40.7c7.4 0 14.4 2 20.4 5.5v-92.4c-6 3.5-12.9 5.5-20.4 5.5h-40.7zm0 162.9c-7.4 0-14.4-2-20.4-5.5v25.8c0 22.5 18.2 40.7 40.7 40.7 22.5 0 40.7-18.2 40.7-40.7v-25.8c-6 3.5-12.9 5.5-20.4 5.5h-40.6z"/>'
  }
})
