













import { defineComponent, ref, onMounted, watch } from '@vue/composition-api'
import { RouteRecord } from 'vue-router'
import { useRouter } from '@u3u/vue-hooks'

export default defineComponent({
  setup() {
    const levelList: any = ref([])
    const { route } = useRouter()

    watch(route, () => {
      getBreadcrumb()
    })
    const getBreadcrumb = () => {
      let matched = route.value.matched.filter((item) => item.name)
      const first = matched[0]
      if (first && first.name !== 'dashboard') {
        matched = [{ path: '/dashboard', meta: { title: '' } } as RouteRecord].concat(matched)
      }
      levelList.value = matched
    }
    onMounted(() => {
      getBreadcrumb()
    })

    return { levelList }
  },
})
