/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'delete-user': {
    width: 430,
    height: 430,
    viewBox: '0 0 1024 1024',
    data: '<defs/><path pid="0" d="M682.667 938.667C657.067 938.667 640 921.6 640 896v-85.333c0-72.534-55.467-128-128-128H213.333c-72.533 0-128 55.466-128 128V896c0 25.6-17.066 42.667-42.666 42.667S0 921.6 0 896v-85.333C0 691.2 93.867 597.333 213.333 597.333H512c119.467 0 213.333 93.867 213.333 213.334V896c0 25.6-17.066 42.667-42.666 42.667zM362.667 512c-119.467 0-213.334-93.867-213.334-213.333S243.2 85.333 362.667 85.333 576 179.2 576 298.667 482.133 512 362.667 512zm0-341.333c-72.534 0-128 55.466-128 128s55.466 128 128 128 128-55.467 128-128-55.467-128-128-128zM981.333 512h-256c-25.6 0-42.666-17.067-42.666-42.667s17.066-42.666 42.666-42.666h256c25.6 0 42.667 17.066 42.667 42.666S1006.933 512 981.333 512z"/>'
  }
})
