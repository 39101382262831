


































































































import { defineComponent, ref, nextTick } from '@vue/composition-api'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { useGetScreenshot, useUploadImage, sendNotifyToWebhook } from '@/apis/common/useScreenshot'
import { useCountDown } from '@/lib/utils/useCountDown'
const count = 60

export default defineComponent({
  components: {
    ValidationObserver,
    ValidationProvider,
  },
  setup() {
    const feedbackRemark = ref('')
    const screenshotVisible = ref(false)
    const observerVal = ref<typeof ValidationObserver | null>(null)
    const { formData, screenshotLoading, canvasCopy, screenshotImg } = useGetScreenshot()
    const { imgRes, uploadLoading, uploadHandler } = useUploadImage()
    const { sendLoading, sendHandler } = sendNotifyToWebhook()
    const { num, startHandler } = useCountDown()

    const jumpToLark = () => {
      window.open('https://applink.larksuite.com/TJo3ShU', '_blank')
    }

    const screenshotHandler = async () => {
      screenshotVisible.value = true
      await screenshotImg()
      await nextTick()
      const canvasDom = document.querySelector('.canvas-obj') as any
      canvasCopy.value.style.width = 100 + '%'
      canvasCopy.value.style.height = 100 + '%'
      canvasDom.append(canvasCopy.value)
      await nextTick()
    }

    const submitFeedbackHandler = async () => {
      const isValid = await (observerVal.value as any).validate()
      if (!isValid) {
        return
      }
      await uploadHandler(formData.value)
      if (!imgRes.value.image_key) {
        return
      }
      await sendHandler('用户主动反馈', imgRes.value.image_key, [], feedbackRemark.value)
      screenshotVisible.value = false
      startHandler(count)
      resetFeedbackHandler()
    }

    const resetFeedbackHandler = () => {
      const canvasDom = document.querySelector('.canvas-obj') as any
      canvasDom.innerHTML = ''
      feedbackRemark.value = ''
      // tslint:disable-next-line
      ;(observerVal.value as any).reset()
    }

    const cancelFeedbackHandler = () => {
      resetFeedbackHandler()
      screenshotVisible.value = false
    }

    return {
      feedbackRemark,
      screenshotLoading,
      canvasCopy,
      jumpToLark,
      screenshotHandler,
      screenshotVisible,
      observerVal,
      cancelFeedbackHandler,
      submitFeedbackHandler,
      uploadLoading,
      sendLoading,
      num,
    }
  },
})
