/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'user-audit': {
    width: 500.488,
    height: 500,
    viewBox: '0 0 1025 1024',
    data: '<defs/><path pid="0" d="M358.2 558.6c-114.8 0-208.2-93.4-208.2-208.2s93.4-208.2 208.2-208.2 208.2 93.4 208.2 208.2S473 558.6 358.2 558.6zm0-336.3c-70.7 0-128.2 57.5-128.2 128.2 0 70.7 57.5 128.2 128.2 128.2s128.2-57.5 128.2-128.2c0-70.7-57.5-128.2-128.2-128.2zM673 1024c-22.1 0-40-17.9-40-40V807.6c0-72-58.6-130.6-130.6-130.6H214c-72 0-130.6 58.6-130.6 130.6V984c0 22.1-17.9 40-40 40s-40-17.9-40-40V807.6C3.4 691.5 97.9 597 214 597h288.4C618.5 597 713 691.5 713 807.6V984c0 22.1-17.9 40-40 40z"/><path pid="1" d="M162.5 1024c-22.1 0-40-17.9-40-40v-81.9c0-22.1 17.9-40 40-40s40 17.9 40 40V984c0 22.1-17.9 40-40 40zm391.5 0c-22.1 0-40-17.9-40-40v-81.9c0-22.1 17.9-40 40-40s40 17.9 40 40V984c0 22.1-17.9 40-40 40zm391-767H717c-22.1 0-40-17.9-40-40s17.9-40 40-40h228c22.1 0 40 17.9 40 40s-17.9 40-40 40zm-62 161H717c-22.1 0-40-17.9-40-40s17.9-40 40-40h166c22.1 0 40 17.9 40 40s-17.9 40-40 40zm102 161H717c-22.1 0-40-17.9-40-40s17.9-40 40-40h268c22.1 0 40 17.9 40 40s-17.9 40-40 40z"/>'
  }
})
