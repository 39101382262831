import Vue from 'vue'
import 'normalize.css'
import '@/assets/styles/reset.css'
import ElementUI from 'element-ui'
// tslint:disable-next-line
const locale = require('element-ui/lib/locale/lang/en').default
import SvgIcon from 'vue-svgicon'
import '@/assets/styles/index.scss'
import '@/lib/icons'
import '@/permission'
import App from '@/App.vue'
import store from '@/store'
import errorHandler, { handleError as handleErrorFn } from '@/lib/error-handler'
import axios from '@/lib/axios'
import safeGet from '@/lib/safe-get'
import filters from '@/lib/filters'
import clipboard from '@/lib/clipboard'
import CustomUi from '@/lib/custom-ui'
import { pluginFunction as VueMarkupFormPlugin } from 'vue-markup-form'
import VueCompositionAPI from '@vue/composition-api'
import hooks from '@u3u/vue-hooks'
// tslint:disable-next-line
const router = require('@/router').default
// tslint:disable-next-line
const TreeView = require('vue-json-tree-view')
// vee-validate rules
import { ValidationProvider } from 'vee-validate'
import '@/lib/rules'
// 调试工具
import initVconsoleAsync from '@/lib/v-console'

// Register it globally
Vue.component('ValidationProvider', ValidationProvider)
Vue.use(ElementUI, {
  size: 'small',
  locale,
})
Vue.use(SvgIcon, {
  tagName: 'svg-icon',
  defaultWidth: '1em',
  defaultHeight: '1em',
})
Vue.use(CustomUi)
Vue.use(errorHandler)
Vue.use(axios)
Vue.use(safeGet)
Vue.use(filters)
Vue.use(clipboard)
Vue.use(TreeView)
Vue.use(VueMarkupFormPlugin)
Vue.use(hooks)
Vue.use(VueCompositionAPI)

// 设置为 false 以阻止 vue 在启动时生成生产提示
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')

if (['localhost', 'sso-staging.advai.net'].includes(location.hostname)) {
  initVconsoleAsync()
}
