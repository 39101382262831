/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'organization': {
    width: 430,
    height: 430,
    viewBox: '0 0 1024 1024',
    data: '<defs/><path pid="0" d="M832 384H192c-70.656 0-128 57.344-128 128v64c0 47.264 25.856 88.064 64 110.24V896h256v128h256V896h256V686.24c38.112-22.176 64-62.944 64-110.24v-64c0-70.656-57.376-128-128-128zM320 832H192V576h-64v-64c0-35.328 28.704-64 64-64h81.728C262.72 466.88 256 488.576 256 512v128c0 47.264 25.856 88.064 64 110.24V832zm320-128V576h-64v384H448V576h-64v128c-35.328 0-64-28.64-64-64V512c0-35.328 28.672-64 64-64h256c35.36 0 64 28.672 64 64v128c0 35.36-28.64 64-64 64zm256-128h-64v256H704v-81.76c38.112-22.176 64-62.944 64-110.24V512c0-23.424-6.752-45.12-17.76-64H832c35.36 0 64 28.672 64 64v64zM367.68 317.376C402.88 357.888 454.144 384 512 384s109.12-26.112 144.32-66.624C678.144 356.928 719.68 384 768 384c70.624 0 128-57.344 128-128s-57.376-128-128-128c-25.632 0-49.376 7.68-69.376 20.672C678.88 63.552 603.072 0 512 0S345.088 63.552 325.376 148.672C305.344 135.68 281.6 127.968 256 127.968c-70.656 0-128 57.344-128 128s57.344 128 128 128c48.32 0 89.856-27.072 111.68-66.624zM768 192c35.36 0 64 28.608 64 64s-28.64 64-64 64c-35.328 0-64-28.608-64-64s28.672-64 64-64zM512 64c70.624 0 128 57.344 128 128s-57.376 128-128 128c-70.656 0-128-57.344-128-128S441.344 64 512 64zM256 320c-35.328 0-64-28.608-64-64s28.672-64 64-64c35.392 0 64 28.608 64 64s-28.608 64-64 64z"/>'
  }
})
