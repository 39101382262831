/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'configuration': {
    width: 423.438,
    height: 400,
    viewBox: '0 0 1084 1024',
    data: '<defs/><path pid="0" d="M825.224 90.353c-18.071-36.141-60.236-60.235-102.4-60.235s-84.33 24.094-102.4 60.235H0v120.47h620.424c18.07 36.142 60.235 60.236 102.4 60.236s84.329-24.094 102.4-60.235h259.011V90.353H825.224zM542.118 391.529c-42.165 0-78.306 24.095-102.4 54.212H0v120.47h433.694c18.07 36.142 60.235 66.26 108.424 66.26s84.33-24.095 108.423-66.26h433.694v-120.47H644.518c-24.094-30.117-60.236-54.212-102.4-54.212zm240.94 361.412c-42.164 0-72.282 18.07-96.376 48.188H0V921.6h674.635c18.07 42.165 60.236 72.282 108.424 72.282s90.353-30.117 108.423-72.282h192.753V801.13h-204.8c-24.094-30.118-54.211-48.189-96.376-48.189z"/>'
  }
})
