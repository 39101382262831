import axios, { AxiosResponse, AxiosInstance, AxiosRequestConfig } from 'axios'
import qs from 'querystring'
import _Vue from 'vue'

export interface IAxiosResponse<T> extends AxiosResponse {
  code: number
  data: T
  msg: string
}

const instance = axios.create({
  baseURL: '/api/v1',
  timeout: 60000,
  paramsSerializer: (params) => {
    return qs.stringify(params)
  },
})

const requestInterceptor = (config: AxiosRequestConfig) => {
  return config
}
const responseInterceptor = (response: AxiosResponse<IAxiosResponse<any>>) => {
  // http status 为 200 不会执行
  // TODO: throw custom http error
  // if (response.data.code !== 200) {
  //   throw {
  //     isCustomApiError: true,
  //     response,
  //   }
  // }
  return response
}

instance.interceptors.request.use(requestInterceptor)
instance.interceptors.response.use(responseInterceptor)

declare module 'vue/types/vue' {
  // tslint:disable-next-line:interface-name
  interface Vue {
    $http: AxiosInstance
  }
  // tslint:disable-next-line:interface-name
  interface VueConstructor<V extends Vue> {
    http: AxiosInstance
  }
}

export default {
  install(Vue: typeof _Vue, options?: any) {
    Vue.http = instance
    Vue.prototype.$http = instance
  },
}
export { instance as axios }
