/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'approvals': {
    width: 430,
    height: 430,
    viewBox: '0 0 1024 1024',
    data: '<defs/><path pid="0" d="M146.286 841.143v73.143h731.428v-73.509H182.857l-36.571.366zM768 475.429H658.286a36.571 36.571 0 01-36.572-36.572V421.67a44.617 44.617 0 0115.36-30.72A182.857 182.857 0 00467.75 80.09a182.857 182.857 0 00-80.092 311.223 42.789 42.789 0 0114.994 30.355v17.188a36.571 36.571 0 01-36.571 36.572H256a109.714 109.714 0 00-109.714 109.714V768h731.428V585.143A109.714 109.714 0 00768 475.429zm36.571 219.428H219.43V585.143A36.571 36.571 0 01256 548.57h109.714A109.714 109.714 0 00475.43 438.857V421.67a114.103 114.103 0 00-36.572-83.749 109.714 109.714 0 01-31.451-109.714 109.714 109.714 0 0178.994-78.263A109.714 109.714 0 01621.714 256a109.714 109.714 0 01-34.743 79.726 115.566 115.566 0 00-38.4 84.48v18.651a109.714 109.714 0 00109.715 109.714H768a36.571 36.571 0 0136.571 36.572z"/>'
  }
})
