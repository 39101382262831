/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'relationship': {
    width: 430,
    height: 430,
    viewBox: '0 0 1024 1024',
    data: '<defs/><path pid="0" d="M987.5 548.6c0-213-142.7-400.8-347.3-457.9C621.2 37.9 571.2 0 512 0c-59.3 0-109.2 37.9-128.1 90.7C179.2 147.8 36.5 335.6 36.5 548.6c0 63 12.6 123.7 36.4 181.2-18.1 23.2-29.3 52-29.3 83.6 0 75.4 61.2 136.6 136.6 136.6 20.8 0 40.2-5 57.8-13.3 80.5 56.9 174.7 87.3 273.9 87.3 99.1 0 193.5-30.3 274-87.3C803.6 945 823 950 843.8 950c75.4 0 136.6-61.2 136.6-136.6 0-31.5-11.1-60.1-28.9-83.2 23.8-57.7 36-118.8 36-181.6zM512 81.1c30.6 0 55.5 24.9 55.5 55.5s-24.9 55.5-55.5 55.5-55.5-24.9-55.5-55.5 24.9-55.5 55.5-55.5zM124.7 813.4c0-30.6 24.9-55.5 55.5-55.5s55.5 24.9 55.5 55.5-24.9 55.5-55.5 55.5-55.5-24.9-55.5-55.5zm174 66.9c11.2-19.8 18.2-42.5 18.2-66.9 0-75.4-61.2-136.6-136.6-136.6-13.5 0-26.3 2.6-38.7 6.2-15.6-42.9-23.9-88-23.9-134.5 0-168.3 107.4-317.5 264.3-372.3 17.1 56 68.5 96.9 130.1 96.9 61.5 0 113-41 130.1-96.9 156.9 54.8 264.3 204 264.3 372.3 0 46.3-8.4 91.4-24 134.4-12.3-3.6-25.1-6.2-38.6-6.2-75.4 0-136.6 61.2-136.6 136.6 0 24.4 6.9 47 18.1 66.8-63.6 41-136.7 62.7-213.3 62.7-76.8.1-149.9-21.5-213.4-62.5zm545.1-11.4c-30.6 0-55.5-24.9-55.5-55.5s24.9-55.5 55.5-55.5 55.5 24.9 55.5 55.5-24.9 55.5-55.5 55.5z"/>'
  }
})
