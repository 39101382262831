/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'common_email': {
    width: 600,
    height: 600,
    viewBox: '0 0 1024 1024',
    data: '<defs/><path pid="0" d="M512.001 990.98c-263.004 0-477.868-215.366-477.868-478.977 0-263.617 214.864-478.982 477.868-478.982 264.173 0 477.866 215.365 477.866 478.982C989.867 775.614 775 990.979 512 990.979zm0-910.885c-237.174 0-430.906 193.006-430.906 431.908 0 238.898 193.732 431.901 430.906 431.901 238.341 0 430.9-194.18 430.9-431.901 0-237.727-193.73-431.908-430.9-431.908zm272.95 655.432H253.16c-29.454 0-53.425-24.685-53.425-55.175V343.701c0-30.473 23.971-55.22 53.425-55.22H784.95c29.575 0 53.426 24.747 53.426 55.22v336.651c.001 30.488-23.85 55.175-53.424 55.175zm-14.106-39.537L604.6 543.57l-27.236 24.687c-15.887 14.273-35.258 21.607-58.308 21.607-22.927 0-42.42-7.335-58.25-21.607l-27.236-24.686L267.33 695.99h503.515zM239.477 363.885v304.173l165.62-154.196-165.62-149.977zm26.47-35.866l212.066 194.13c18.51 17.044 32.216 25.51 41.042 25.51 8.827 0 22.532-8.464 41.043-25.51l212.066-194.13H265.946zm532.707 35.866L633.033 513.862l165.621 154.196V363.885z"/>'
  }
})
