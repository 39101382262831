<template>
  <div class="list">
    <slot :data="currentData"></slot>
    <div class="pager-container">
      <el-pagination
        class="pagination"
        v-if="filterdData"
        @current-change="currentChangeHandler"
        layout="prev, pager, next"
        :page-size="page.size"
        :currentPage="page.currentPage"
        :total="filterdData.length"
        background
      ></el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MainPage',
  props: {
    data: Array,
    filters: Object, // key must same with props
  },
  data() {
    return {
      allFilters: {}, // fucking element ui
      filterdData: [],
      page: {
        size: 20,
        currentPage: 0,
      },
    }
  },
  computed: {
    currentData() {
      if (!this.filterdData) {
        return null
      }
      const start = (this.page.currentPage - 1) * this.page.size
      const end = this.page.currentPage * this.page.size
      return this.filterdData.slice(start, end)
    },
  },
  watch: {
    currentData(newValue, oldValue) {
      this.$emit('change', newValue)
    },
    data(newValue, oldValue) {
      this.filterdData = this.filterRawData(newValue, this.allFilters)
      this.currentChangeHandler(1)
    },
    // 之所以这么做是以为，element 没有传所有 filter 选项，并且 watch 添加的 data 没有触发 computed 属性
    filters(newValue, oldValue) {
      Object.keys(newValue).forEach((key) => {
        this.allFilters[key] = newValue[key]
      })
      this.filterdData = this.filterRawData(this.data, this.allFilters)
      this.currentChangeHandler(1)
    },
  },
  methods: {
    filterRawData(data, filters = {}) {
      if (!data) {
        return []
      }
      return data.filter((item) => {
        return Object.keys(filters).every((key) => {
          return (
            filters[key].length === 0 ||
            filters[key].some((val) => {
              return val === item[key]
            })
          )
        })
      })
    },
    currentChangeHandler(val) {
      this.page.currentPage = val
    },
  },
  filters: {},
}
</script>

<style lang="scss" scoped>
.pager-container {
  margin-top: 20px;
  float: right;
}
.list {
  overflow: hidden;
}
.pagination {
  display: flex;
  justify-content: flex-end;
  margin: 20px 0 0;
}
</style>
