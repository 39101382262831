import _Vue from 'vue'
import { Notification, Message } from 'element-ui'
import { ErrorCode, IResponseError } from '@/interfaces/response'
import { AxiosError, AxiosResponse } from 'axios'
import router from '@/router'
import qs from 'querystring'
import { getErrorLog } from '@/lib/utils/getErrorLog'
import { UserModule } from '@/store/modules/user'

const duration = 0
const { collectErrorLog } = getErrorLog()

export function isAxiosError(object: any): object is AxiosError {
  return object.config !== undefined
}

declare module 'vue/types/vue' {
  // tslint:disable-next-line:interface-name
  interface Vue {
    $handleError: (error: any) => void
  }
  // tslint:disable-next-line:interface-name
  interface VueConstructor<V extends Vue> {
    handleError: (error: any) => void
  }
}

export function handleError(error: any) {
  // TODO: handler custom http error
  // else if (error.isCustomApiError) {
  //   const response = error.response
  //   Notification.error({
  //     title: 'error',
  //     message: response.data.msg,
  //     duration,
  //   })
  // }

  // isAxiosError: 服务端报错、请求无响应(超时)、断网等
  if (isAxiosError(error)) {
    if (error.response) {
      // 服务端响应报错: 断网、http status 不为 2xx 的请求
      const response = error.response as any
      switch (response.status) {
        case 401:
          const queryObj = qs.parse(window.location.search.replace('?', ''))
          if (!queryObj.next) {
            router.push({
              path: `/login`,
              query: {
                next: window.location.href,
              },
            })
          }
          Message({
            message: response.data.msg,
            type: 'error',
            duration,
            showClose: true,
          })
          return
        default:
          Notification.error({
            title: 'error',
            message: response.data.msg || error.message,
            duration,
          })
          break
      }
    } else if (error.request) {
      // 客户端响应报错: 网络不佳, 或接口请求超时, 或请求跨域
      console.log(error.request)
      Notification.error({
        title: 'error',
        message: 'Network error',
        duration,
      })
    } else {
      // 其他: 建立 http 链接时错误
      Notification.error({
        title: 'error',
        message: 'Server error',
        duration,
      })
    }
  } else {
    // 前端代码抛出错误: try...catch... 到的js逻辑
    console.error(error)
    Notification.error({
      title: 'Front-end error',
      message: 'Front-end logic exception',
      duration,
    })
  }

  // 登录后才捕获
  if (UserModule.username) {
    collectErrorLog(error)
    // tslint:disable-next-line
    ;(window as any).onCollectErrorLog()
  }
}

export default {
  install(Vue: typeof _Vue, options?: any) {
    Vue.handleError = handleError
    Vue.prototype.$handleError = handleError
  },
}
