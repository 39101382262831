/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'nested': {
    width: 227.93,
    height: 200,
    viewBox: '0 0 1167 1024',
    data: '<defs/><path pid="0" d="M.016 74.46a2.286 2.286 1440 10145.836 0 2.286 2.286 1440 10-145.836 0zM291.72 1.524h875.081v145.837h-875.08zm0 364.639a2.286 2.286 1440 10145.837 0 2.286 2.286 1440 10-145.837 0zm291.705-72.934H1166.8v145.836H583.425zM291.72 949.54a2.286 2.286 1440 10145.837 0 2.286 2.286 1440 10-145.837 0zm291.705-72.902H1166.8v145.837H583.425zm0-218.802a2.286 2.286 1440 10145.836 0 2.286 2.286 1440 10-145.836 0zm291.704-72.902h291.672V730.77H875.13z"/>'
  }
})
