













































import { computed, defineComponent, onMounted, ref } from '@vue/composition-api'
import { useRouter } from '@u3u/vue-hooks'
import { AppModule } from '@/store/modules/app'
import SidebarItem from './SidebarItem.vue'
// tslint:disable-next-line
const config = require('../../../../../package.json')

export default defineComponent({
  components: {
    SidebarItem,
  },
  setup() {
    const hostname = ref('')
    const { router } = useRouter()

    const isKP = computed(() => {
      if (location.hostname === 'sso.kreditpintar.com') {
        return true
      }
      return false
    })

    const sidebar = computed(() => {
      return AppModule.sidebar
    })
    const isRefresh = computed(() => {
      return AppModule.isRefresh
    })
    const routes = computed(() => {
      return (router as any).options.routes
    })
    const isCollapse = computed(() => {
      return !sidebar.value.opened
    })
    const version = computed(() => {
      return config.version
    })

    onMounted(() => {
      hostname.value = window.location.hostname
    })

    return { version, sidebar, routes, isCollapse, hostname, isRefresh, isKP }
  },
})
