














import { Vue, Component, Prop, Watch } from 'vue-property-decorator'

@Component({
  name: 'MainImage',
})
export default class MainImage extends Vue {
  @Prop(String) photoName!: string
  photoUrl: string = ''
  visible: boolean = false

  @Watch('photoName')
  async onPhotoNameChanged(newValue: string, oldValue: string) {
    if (newValue) {
      try {
        const params = {
          filename: newValue,
        }
        this.photoUrl = ''
        const { data } = await this.$http.get('/images', { params })
        this.photoUrl = data.url
      } catch (e) {
        this.$handleError(e)
      }
    }
  }
  clickHandler() {
    this.visible = true
    if (this.photoName) {
      this.onPhotoNameChanged(this.photoName, '')
    }
  }
}
