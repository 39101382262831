import { ref } from '@vue/composition-api'

export const useCountDown = () => {
  let timer: any = null
  const numCopy = ref(0)

  const startHandler = (num: number) => {
    numCopy.value = num
    timer = setInterval(() => {
      if (numCopy.value <= 0) {
        clearInterval(timer)
        return
      }
      numCopy.value--
    }, 1000)
  }

  return { num: numCopy, startHandler }
}
