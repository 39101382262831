/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'question-bank': {
    width: 200,
    height: 200,
    viewBox: '0 0 1024 1024',
    data: '<path pid="0" d="M736.028 96.028a128 128 0 01128 128v575.944a128 128 0 01-128 128H287.915a128 128 0 01-128-128V224.028a128 128 0 01128-128h448zm.91 71.965H287.973a55.979 55.979 0 00-55.98 55.068v576.967c0 30.607 24.577 55.467 55.07 55.98h448.91a55.979 55.979 0 0056.035-55.07V223.973a55.979 55.979 0 00-55.068-55.98zM571.962 668.046a36.01 36.01 0 110 71.965h-183.98a36.01 36.01 0 110-72.022h184.036zm64.057-192.057a36.01 36.01 0 110 72.022H387.982a36.01 36.01 0 010-72.022h248.036zm0-192a36.01 36.01 0 110 72.022H387.982a36.01 36.01 0 010-72.022h248.036z"/>'
  }
})
