/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'offboarding': {
    width: 200,
    height: 200,
    viewBox: '0 0 1028 1024',
    data: '<path pid="0" d="M503.467 550.4c53.333 0 104.533 10.667 153.6 29.867 23.466 8.533 44.8 21.333 66.133 34.133 19.2 12.8 46.933 6.4 59.733-12.8 12.8-19.2 6.4-46.933-12.8-59.733-25.6-14.934-51.2-29.867-78.933-40.534-6.4-2.133-12.8-4.266-17.067-6.4 66.134-49.066 108.8-128 108.8-215.466 0-149.334-121.6-273.067-273.066-273.067s-275.2 121.6-275.2 270.933c0 87.467 40.533 164.267 104.533 213.334C142.933 558.933 0 746.667 0 966.4v42.667h588.8c23.467 0 42.667-19.2 42.667-42.667s-19.2-42.667-42.667-42.667H89.6C110.933 712.533 288 550.4 503.467 550.4zm4.266-460.8c102.4 0 187.734 83.2 187.734 187.733s-83.2 187.734-187.734 187.734c-102.4 0-187.733-83.2-187.733-187.734S403.2 89.6 507.733 89.6zM915.2 842.667l59.733-59.734c17.067-17.066 17.067-42.666 0-59.733-17.066-17.067-42.666-17.067-59.733 0l-59.733 59.733-59.734-59.733c-17.066-17.067-42.666-17.067-59.733 0s-17.067 42.667 0 59.733l59.733 59.734L736 902.4c-17.067 17.067-17.067 42.667 0 59.733 17.067 17.067 42.667 17.067 59.733 0l59.734-59.733 59.733 59.733c17.067 17.067 42.667 17.067 59.733 0s17.067-42.666 0-59.733L915.2 842.667z" _fill="#020202"/>'
  }
})
