/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'department': {
    width: 300.293,
    height: 300,
    viewBox: '0 0 1025 1024',
    data: '<defs/><path pid="0" d="M192.314 523.141H490.95v123.572h42.466V523.136h254.81c70.964 0 84.938 41.375 84.938 123.577h41.11v-83.245c5.504-40.582-23.605-77.856-65.011-83.25a77.2 77.2 0 00-19.967.004h-295.92v-83.24h-42.467v83.245l-324.076.5c-56.894 2.797-101.338 49.192-100.61 105.012v60.974h41.152c0-63.596-4.245-123.532 84.939-123.572zM369.99 313.736h280.968c29.078-1.568 51.41-25.852 49.983-54.361V118.362c1.422-28.51-20.91-52.794-49.983-54.362H369.99c-29.054 1.588-51.35 25.872-49.902 54.362V259.38c-1.448 28.484 20.843 52.768 49.902 54.356zM217.335 710.274H38.418c-24.809 4.834-41.453 27.81-37.926 52.358v145.01C-3.014 932.185 13.62 955.145 38.418 960h178.917c24.803-4.855 41.447-27.81 37.966-52.358v-145.01c3.506-24.553-13.153-47.524-37.966-52.358zm384.175 0H422.547c-24.823 4.815-41.472 27.805-37.925 52.358v145.01c-3.507 24.543 13.127 47.503 37.925 52.358H601.51c24.798-4.855 41.427-27.815 37.926-52.358v-145.01c3.521-24.548-13.118-47.524-37.926-52.358zm422.04 52.358c3.527-24.558-13.137-47.543-37.966-52.358H806.667c-24.808 4.834-41.452 27.81-37.925 52.358v145.01c-3.507 24.543 13.127 47.503 37.925 52.358h178.917c24.814-4.835 41.468-27.805 37.967-52.358v-145.01zM384.022 160.3c0-17.84 13.768-32.3 31.401-32.3h193.282c17.342 0 31.4 13.699 31.4 32.3v63.4c0 17.84-13.768 32.3-31.4 32.3H415.422c-17.342 0-31.4-13.699-31.4-32.3v-63.4z"/>'
  }
})
