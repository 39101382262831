import _Vue from 'vue'
function safeGet(obj: any, path: string, defaultValue?: string | number): any {
  const isDict = (v: any) => {
    return (
      typeof v === 'object' &&
      v !== null &&
      !(v instanceof Array) &&
      !(v instanceof Date)
    )
  }
  return path
    ? path.split('.').reduce((pv, ck) => {
        if (isDict(pv)) {
          return typeof pv[ck] === 'undefined' || pv[ck] === null
            ? defaultValue
            : pv[ck]
        } else {
          return defaultValue
        }
      }, obj)
    : obj
}
declare module 'vue/types/vue' {
  // tslint:disable-next-line:interface-name
  interface Vue {
    $safe: (obj: any, path: string, defaultValue?: string | number | []) => any
  }
}

export default {
  install(Vue: typeof _Vue, options?: any) {
    Vue.prototype.$safe = safeGet
  },
}
