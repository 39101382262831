






















































import { defineComponent, computed } from '@vue/composition-api'
import { UserModule } from '@/store/modules/user'
import { IModule } from '@/interfaces/admin-user'
import { useRouter } from '@u3u/vue-hooks'
import { RouteConfig } from 'vue-router'
import path from 'path'
import Item from './Item.vue'

export default defineComponent({
  components: {
    Item,
  },
  props: {
    item: {
      type: Object as () => RouteConfig,
      required: true,
    },
    isNest: {
      type: Boolean,
      required: false,
    },
    basePath: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const { router } = useRouter()

    const showingChildren = computed(() => {
      if (!props.item.children) {
        return []
      }
      return props.item.children.filter((item: RouteConfig) => {
        if (item.meta && item.meta.hidden) {
          return false
        } else if (item.meta && item.meta.moduleName) {
          return UserModule.appModules.find((_: IModule) => _.name === item.meta.moduleName)
        } else {
          return true
        }
      })
    })

    const resolvePath = (routePath: string) => {
      return path.resolve(props.basePath, routePath)
    }

    const validateURL = (textval: string) => {
      const urlregex = /^(https?|ftp):\/\/([a-zA-Z0-9.-]+(:[a-zA-Z0-9.&%$-]+)*@)*((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3}|([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.(com|edu|gov|int|mil|net|org|biz|arpa|info|name|pro|aero|coop|museum|[a-zA-Z]{2}))(:[0-9]+)*(\/($|[a-zA-Z0-9.,?'\\+&%$#=~_-]+))*$/
      return urlregex.test(textval)
    }

    const isExternalLink = (routePath: string) => {
      return validateURL(routePath)
    }

    const clickLink = (routePath: string, e: Event) => {
      if (!isExternalLink(routePath)) {
        e.preventDefault()
        router.push(resolvePath(routePath))
      }
    }

    const subChildrenFilter = (children?: RouteConfig[]) => {
      const curChildren = children || []
      return curChildren.filter((_) => !(_.meta && _.meta.hidden))
    }

    const isHiddenParentBadge = (title: string) => {
      switch (title) {
        default:
          return true
      }
    }

    const isHiddenSubBadge = (name: string) => {
      switch (name) {
        default:
          return true
      }
    }

    return { isHiddenSubBadge, isHiddenParentBadge, showingChildren, resolvePath, clickLink, subChildrenFilter }
  },
})
