/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'title': {
    width: 500,
    height: 500,
    viewBox: '0 0 1024 1024',
    data: '<defs/><path pid="0" d="M629.824 256H394.176a32 32 0 01-31.232-25.152l-42.176-192A32 32 0 01352 0h320a32.128 32.128 0 0131.296 38.912l-42.176 192A32.192 32.192 0 01629.824 256zm-209.92-64h184.128l28.096-128h-240.32l28.096 128zM512 1024a32.192 32.192 0 01-23.616-10.368l-224-244.416a32.128 32.128 0 01-7.68-28.352l85.568-395.584A32 32 0 01373.568 320h276.864a32 32 0 0131.232 25.28l85.568 395.584a31.808 31.808 0 01-7.68 28.352l-224 244.416A31.936 31.936 0 01512 1024zM322.752 738.112L512 944.64l189.248-206.528L624.64 384H399.36l-76.608 354.112z"/>'
  }
})
