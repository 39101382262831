/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'radar': {
    width: 430,
    height: 430,
    viewBox: '0 0 1024 1024',
    data: '<defs/><path pid="0" d="M989.867 371.2l-443.734-320C537.6 44.8 524.8 40.533 512 40.533s-25.6 2.134-34.133 10.667l-443.734 320C14.933 384 6.4 413.867 12.8 437.333l168.533 520.534c6.4 21.333 32 40.533 55.467 40.533h548.267c23.466 0 49.066-19.2 55.466-40.533l168.534-520.534c8.533-21.333 0-51.2-19.2-66.133zM834.133 774.4l-29.866-49.067 76.8-238.933c6.4-21.333-2.134-51.2-21.334-66.133l-313.6-226.134c-8.533-6.4-21.333-10.666-34.133-10.666s-25.6 4.266-34.133 10.666L162.133 422.4c-19.2 12.8-27.733 42.667-21.333 66.133l119.467 371.2c6.4 21.334 32 40.534 55.466 40.534H704c23.467 0 49.067-19.2 55.467-40.534L780.8 793.6l42.667 8.533-42.667 134.4H241.067L74.667 422.4 512 104.533 949.333 422.4l-115.2 352zM202.667 471.467l224-162.134-179.2 296.534-44.8-134.4zm518.4 307.2L704 834.133H322.133L275.2 689.067l445.867 89.6zm-198.4-524.8l298.666 217.6L761.6 652.8 522.667 253.867z"/>'
  }
})
