/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'group_email': {
    width: 500,
    height: 500,
    viewBox: '0 0 1024 1024',
    data: '<defs/><path pid="0" d="M841.3 191.8H182.6C117.2 191.8 64 244 64 308.1v407.2c0 64.2 53.2 116.3 118.6 116.3h658.7c65.4 0 118.6-52.2 118.6-116.3V308.1c0-64.1-53.2-116.3-118.6-116.3zm74.3 523.6c0 13-3.5 25.2-9.6 35.7l-184.4-165c-9-8.1-23.1-7.5-31.3 1.4-8.3 8.9-7.6 22.6 1.4 30.7L873.6 781c-9.8 4.7-20.7 7.3-32.3 7.3H182.6c-11.4 0-22.2-2.5-31.8-7l181.5-162.5c9-8.1 9.7-21.8 1.4-30.7-8.3-8.9-22.3-9.5-31.3-1.4L118.1 751.6c-6.3-10.7-9.8-23-9.8-36.2V308.1c0-14.3 4.2-27.6 11.5-38.9L310.9 422c9.5 7.6 23.5 6.2 31.2-3.1 7.7-9.3 6.3-23-3.2-30.6L154.3 240.7c8.7-3.5 18.3-5.5 28.3-5.5h658.7c10.2 0 19.9 2 28.8 5.7l-185 147.8c-9.5 7.6-10.9 21.3-3.2 30.6s21.7 10.7 31.2 3.1l191.2-152.9c7.1 11.2 11.3 24.4 11.3 38.5v407.4z"/><path pid="1" d="M562.5 426.8c6.3 0 11.2 5.3 10.7 11.4-4.8 54.6-7.1 83-7.1 85.2 0 22.9 6.7 34.3 20 34.3 12.4 0 22.6-6.6 30.7-19.9 8.1-13.2 12.1-30.5 12.1-51.7 0-31-10-56.4-30.1-76.1-20.1-19.7-47.8-29.5-83.3-29.5-34.6 0-63.2 11.9-85.9 35.6-22.7 23.7-34 53.4-34 89 0 34.9 10.6 62.9 31.9 84.2 21.2 21.3 50.2 31.9 86.8 31.9 22.2 0 42.2-2.9 59.7-8.7 6.9-2.3 14.1 2.8 14.1 10 0 4.4-2.8 8.4-7 9.9-19 6.7-42 10-69 10-42.6 0-77-12.6-103.2-37.7-26.2-25.1-39.3-58-39.3-98.6 0-42 13.7-76.9 41.2-104.7 27.5-27.8 62.7-41.7 105.6-41.7 40.1 0 73.2 11.6 99.3 34.7 26.1 23.1 39.1 53.2 39.1 90.3 0 27.6-7 50.2-21 67.7s-31.1 26.2-51.2 26.2c-25.7 0-38.6-13.3-38.9-40h-.7c-8.7 26.7-25.3 40-49.8 40-15.9 0-28.9-5.7-38.8-17.2-9.9-11.5-14.9-27.1-14.9-46.9 0-25.9 6.8-47.5 20.5-64.6 13.7-17.1 31.3-25.7 52.8-25.7 8.5 0 16 2.1 22.5 6.3 6.6 4.2 10.7 9.4 12.4 15.7h.7c.1-.3.4-3.6.9-9.8.5-5.4 5.1-9.6 10.7-9.6h2.5zm-96.4 87.9c0 12.9 2.8 23.2 8.4 31 5.6 7.8 13.5 11.7 23.8 11.7 14.2 0 25.4-7 33.6-20.9 8.2-13.9 12.3-32.3 12.3-55.2 0-24-10.3-36-30.9-36-14 0-25.3 6.7-34.1 20.1-8.7 13.4-13.1 29.9-13.1 49.3z"/>'
  }
})
