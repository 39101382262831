/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'pre-add-user': {
    width: 430,
    height: 430,
    viewBox: '0 0 1024 1024',
    data: '<defs/><path pid="0" d="M894.4 794.8H789.9v104.4c0 20.1-16.5 36.6-36.7 36.6s-36.7-16.5-36.7-36.6V794.8H612c-20.2 0-36.7-16.5-36.7-36.6s16.5-36.6 36.7-36.6h104.5V617.1c0-20.1 16.5-36.6 36.7-36.6s36.7 16.5 36.7 36.6v104.4h104.5c20.2 0 36.7 16.5 36.7 36.6-.1 20.2-16.5 36.7-36.7 36.7zM545.1 564.2c-3.8 1.3-7.7 2.4-12 2.4-201.7 0-356.8 154.8-356.8 356.3 0 20.1-21.5 36.6-41.7 36.6S92.9 943 92.9 922.9c0-191.5 124.4-354.9 296.3-414.8-61.5-44.6-101.8-116.8-101.8-198.7 0-135.5 110-245.4 245.7-245.4s245.8 109.9 245.8 245.4c0 131.5-103.7 248.5-233.8 254.8zm-11.9-416.4c-88.5 0-160 71.4-160 159.8s71.5 159.8 160 159.8c88.4 0 160-71.4 160-159.8-.1-86.7-71.6-159.8-160-159.8z"/>'
  }
})
