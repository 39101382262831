/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'my-training': {
    width: 200,
    height: 200,
    viewBox: '0 0 1273 1024',
    data: '<path pid="0" d="M516.096 589.568c-13.824 0-27.648-3.328-40.704-10.496L30.976 367.36C20.48 362.496 13.824 352 13.568 340.48s5.888-22.272 16.128-27.904L471.808 73.472c29.44-17.664 64.512-17.664 93.952-.256L990.72 305.92c9.984 5.376 16.128 16.128 15.872 27.392-.256 11.52-6.656 21.76-16.896 26.88L557.824 578.56c-13.312 7.168-27.392 11.008-41.728 11.008zM111.872 337.664l390.656 186.112 1.536.768c8.192 4.352 16.384 4.352 24.576-.256.256-.256.768-.512 1.024-.512L910.08 331.264l-374.272-204.8c-.256-.256-.768-.256-1.024-.512-10.496-6.144-21.504-6.144-31.744.256-.512.256-1.024.512-1.28.768L111.872 337.664z"/><path pid="1" d="M509.184 955.648c-58.112 0-116.48-11.52-170.496-34.816l-90.624-38.912c-46.08-19.712-75.776-64.768-75.776-114.688v-363.52h61.44v363.52c0 25.344 15.104 48.384 38.4 58.368l90.624 38.912c92.672 39.68 199.168 39.936 292.096.256l92.16-39.168c23.552-9.984 38.656-32.768 38.656-58.368V405.248h61.44v361.728c0 50.176-29.696 95.232-76.032 114.944l-92.16 39.168c-54.016 23.04-111.872 34.56-169.728 34.56zm433.664-402.176c-11.264 0-20.48-9.216-20.48-20.48V358.144c0-11.264 9.216-20.48 20.48-20.48s20.48 9.216 20.48 20.48v174.848c0 11.264-8.96 20.48-20.48 20.48z"/><path pid="2" d="M942.848 620.032c-21.248 0-38.4-17.152-38.4-38.4v-46.848c0-21.248 17.152-38.4 38.4-38.4s38.4 17.152 38.4 38.4v46.848c0 21.248-17.152 38.4-38.4 38.4z"/>'
  }
})
