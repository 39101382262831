/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'employee-training': {
    width: 200,
    height: 200,
    viewBox: '0 0 1024 1024',
    data: '<path pid="0" d="M754.688 150.682H522.803V93.85h-61.44v56.883H229.427c-81.613 0-148.07 66.406-148.07 148.019v325.683c0 81.613 66.406 148.07 148.07 148.07h91.085L231.27 906.343l51.098 34.1 111.974-167.936h71.168v150.886h61.44V772.506h71.168l111.975 167.936 51.097-34.1-89.241-133.836h82.739c81.613 0 148.07-66.407 148.07-148.07V298.751c0-81.664-66.457-148.07-148.07-148.07zm86.63 473.702c0 47.77-38.86 86.63-86.63 86.63h-525.26c-47.77 0-86.631-38.86-86.631-86.63V298.752c0-47.77 38.86-86.58 86.63-86.58h525.261c47.77 0 86.63 38.862 86.63 86.58v325.632z"/><path pid="1" d="M338.176 342.733h311.706v61.44H338.176zm0 194.355h311.706v61.44H338.176z"/>'
  }
})
