import _Vue from 'vue'
import dayjs from 'dayjs'

function formatMobileNumber(val: string) {
  if (!val) {
    return ''
  }
  const resultList = val.match(/^\+?(63)\s*(.+)$/)
  if (resultList && resultList.length) {
    return `+${resultList[1]} ${resultList[2]}`
  }
  return val
}
function mosaicMobileNumber(val: string) {
  if (!val) {
    return ''
  }
  const start = val.indexOf(' ') + 1
  const end = val.length - 4
  if (start < end) {
    return val.substring(0, start) + Array(end - start + 1).join('*') + val.substring(end) // add end-start *
  }
  return val
}

function formatMoney(value: any) {
  try {
    if (typeof value === 'string') {
      value = Number(value) - 0
    }
    if (isNaN(value)) {
      return '₱ -'
    }
    return value
      .toFixed(2)
      .replace(/\B(?=(\d{3})+$)/g, ',')
      .replace(/^/, '₱ ')
  } catch (error) {
    return '₱ -'
  }
}

/**
 * 格式化时间
 * @param value timestamp
 * @param formatType 格式化类型字符串，见 dayjs api
 */
function formatTimestamp(value: any, formatType: string = 'DD/MMM/YYYY HH:mm:ss'): string {
  if (!value) {
    return '-'
  }
  try {
    return dayjs(value).format(formatType)
  } catch (error) {
    console.error(error)
    return '-'
  }
}

/**
 * 格式化时间
 * @param value string
 * @returns 格式化字符串为时间戳 (毫秒)
 */
export function formatStringToTimestamp(value: any) {
  if (!value) {
    return ''
  }
  try {
    return dayjs(value).valueOf()
  } catch (error) {
    console.error(error)
    return '-'
  }
}

function formatHistoryStatus(value: any) {
  if (!value) {
    return ''
  }
  switch (value) {
    case 'success':
      return 'success'
    case 'rejected':
      return 'danger'
    case 'approving':
      return ''
    case 'closed':
      return 'info'
  }
}

function formatGender(value: number) {
  if (!value) {
    return ''
  }
  switch (value) {
    case 1:
      return 'Male'
    case 2:
      return 'Female'
  }
}

function formatEmployeeType(value: string) {
  if (!value) {
    return '-'
  }
  switch (value) {
    case 'employee':
      return 'Employee'
    case 'intern':
      return 'Intern'
    case 'agent':
      return 'Outsourced'
    case 'consultancy':
      return 'Consultancy'
    case 'casual_worker':
      return 'Casual Worker'
    default:
      return '-'
  }
}

function sortWeekday(value: string) {
  if (!value) {
    return '-'
  }
  switch (value) {
    case 'Monday':
      return 'Mon.'
    case 'Tuesday':
      return 'Tue.'
    case 'Wednesday':
      return 'Wed.'
    case 'Thursday':
      return 'Thur.'
    case 'Friday':
      return 'Fri.'
    case 'Saturday':
      return 'Sat.'
    case 'Sunday':
      return 'Sun.'
  }
}

function formatReportType(value: string) {
  if (!value) {
    return
  }
  switch (value) {
    case 'false':
      return 'Manager'
    case 'true':
      return 'Co-Manager'
  }
}

function formatPerOnboardingStatus(value: number) {
  if (!value) {
    return
  }
  switch (value) {
    case 1:
      return 'Waiting'
    case 2:
      return 'Processed'
    case 3:
      return 'Cancelled'
  }
}

function formatWorkflowNodeName(value: string) {
  if (!value) {
    return
  }
  switch (value) {
    case 'hrbp':
      return 'HRBP'
    case 'manager':
      return 'Employee Manager'
    case 'it':
      return 'IT'
    case 'ssc':
      return 'SSC'
    case 'finance':
      return 'Finance'
    case 'employee_relation':
      return 'ER'
    case 'account_manager':
      return 'Account Manager'
  }
}

function formatResourceType(value: string) {
  if (!value) {
    return
  }
  switch (value) {
    case 'server':
      return 'Server'
    case 'app_internal':
      return 'Website'
    case 'saml_role':
      return 'Website with Role'
    default:
      return '-'
  }
}

export function getBrowserType(value: string) {
  if (!value) {
    return
  }
  if (value.includes('Firefox')) {
    return 'Mozilla Firefox'
  }
  if (value.includes('Opera') || value.includes('OPR')) {
    return 'Opera'
  }
  if (value.includes('Trident')) {
    return 'Microsoft Internet Explorer'
  }
  if (value.includes('Edge')) {
    return 'Microsoft Edge'
  }
  if (value.includes('Chrome')) {
    return 'Google Chrome'
  }
  if (value.includes('Safari')) {
    return 'Apple Safari'
  }
  return 'unknown'
}

export default {
  install(Vue: typeof _Vue, options = {}) {
    Vue.filter('formatMobileNumber', formatMobileNumber)
    Vue.filter('mosaicMobileNumber', mosaicMobileNumber)
    Vue.filter('formatMoney', formatMoney)
    Vue.filter('formatTimestamp', formatTimestamp)
    Vue.filter('formatStringToTimestamp', formatStringToTimestamp)
    Vue.filter('formatHistoryStatus', formatHistoryStatus)
    Vue.filter('formatGender', formatGender)
    Vue.filter('formatEmployeeType', formatEmployeeType)
    Vue.filter('sortWeekday', sortWeekday)
    Vue.filter('formatReportType', formatReportType)
    Vue.filter('formatPerOnboardingStatus', formatPerOnboardingStatus)
    Vue.filter('formatWorkflowNodeName', formatWorkflowNodeName)
    Vue.filter('formatResourceType', formatResourceType)
    Vue.filter('getBrowserType', getBrowserType)
    Vue.mixin({
      methods: {
        formatMoney(row: any, column: any) {
          return formatMoney(row[column.property])
        },
        formatTimestamp(row: any, column: any) {
          return formatTimestamp(row[column.property])
        },
      },
    })
  },
}
