/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'link': {
    width: 200,
    height: 200,
    viewBox: '0 0 1024 1024',
    data: '<defs/><path pid="0" d="M912 1008.512H15.488V112h448.256v96H111.488v704.512H816V560.256h96z"/><path pid="1" d="M918.208 37.888l67.904 67.904L545.984 545.92l-67.904-67.84z"/><path pid="2" d="M1007.168 310.656h-96V112h-208V16h304z"/>'
  }
})
