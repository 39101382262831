/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'my-approval': {
    width: 430,
    height: 430,
    viewBox: '0 0 1024 1024',
    data: '<defs/><path pid="0" d="M616.03 974.708H81.267a34.723 34.723 0 010-69.445h473.6c16.105 26.763 36.725 50.269 61.161 69.445zm-496.408-129.49h408.855a256.233 256.233 0 01-10.147-68.281l-395.73 1.768-2.978-139.776h217.507a34.723 34.723 0 0034.257-29.137l28.858-177.338a34.676 34.676 0 00-17.966-36.213 147.689 147.689 0 11126.65 5.632 34.583 34.583 0 00-20.573 38.447l32.396 170.356a34.63 34.63 0 0034.07 28.207h2.142a255.441 255.441 0 0163.72-69.446h-37.096L561.43 453.073a216.995 216.995 0 00107.194-187.345A217.46 217.46 0 00451.398 48.5a217.46 217.46 0 00-217.181 217.228c0 71.96 35.328 138.333 93.696 178.641l-20.434 125.115H119.622a66.56 66.56 0 00-66.467 66.467v142.801c0 36.631 29.789 66.467 66.467 66.467zm857.833-71.353a203.543 203.543 0 11-407.134.046 203.543 203.543 0 01407.134-.046zm-78.057-91.834a34.77 34.77 0 00-49.06 2.978l-95.324 107.474-62.557-47.942a34.723 34.723 0 10-42.217 55.11l88.25 67.63a34.35 34.35 0 0021.039 7.168 35.049 35.049 0 0026.112-11.682l116.643-131.678a34.77 34.77 0 00-2.886-49.058z"/>'
  }
})
