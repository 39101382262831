/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'email': {
    width: 500,
    height: 500,
    viewBox: '0 0 1024 1024',
    data: '<defs/><path pid="0" d="M926.72 143.36H97.28c-50.176 0-92.16 41.984-92.16 92.16v552.96c0 51.2 41.984 92.16 92.16 92.16h829.44c51.2 0 92.16-41.984 92.16-92.16V235.52c0-51.2-40.96-92.16-92.16-92.16zM97.28 189.44h829.44c5.12 0 11.264 0 16.384 2.048l-399.36 399.36c-9.216 9.216-20.48 13.312-31.744 13.312s-23.552-5.12-31.744-13.312l-399.36-399.36c5.12-2.048 12.288-2.048 16.384-2.048zM51.2 788.48V228.352L336.896 512 51.2 795.648v-7.168zm875.52 46.08H97.28c-4.096 0-11.264 0-16.384-2.048L368.64 544.768l77.824 77.824c18.432 18.432 40.96 27.648 64.512 27.648s46.08-9.216 64.512-25.6l77.824-78.848 287.744 287.744c-3.072 1.024-9.216 1.024-14.336 1.024zm46.08-46.08v7.168L687.104 512 972.8 228.352V788.48z"/>'
  }
})
