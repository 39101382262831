import MainPage from '@/components/MainPage.vue'
import MainImage from '@/components/MainImage.vue'
import Permission from '@/components/permission'
import _Vue from 'vue'
import atLoadingUtil, { IAtLoadingUtil} from './loading'

const components: { [key: string]: any } = {
  MainPage,
  MainImage,
  Permission,
}

declare module 'vue/types/vue' {
  // tslint:disable-next-line:interface-name
  interface Vue {
    $atLoading: IAtLoadingUtil
  }
}

export default {
  install(Vue: typeof _Vue, options?: any) {
    Object.keys(components).forEach((key: string) => {
      Vue.component(`${key}`, components[key])
    })
    Vue.prototype.$atLoading = atLoadingUtil
  },
}
