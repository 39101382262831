/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'workflow': {
    width: 500,
    height: 500,
    viewBox: '0 0 1024 1024',
    data: '<defs/><path pid="0" d="M580.288 64H443.712c-56.512 0-102.4 46.272-102.4 103.36s45.888 103.424 102.4 103.424h136.576c56.512 0 102.4-46.336 102.4-103.424 0-57.088-45.888-103.36-102.4-103.36zm0 137.856H443.712A34.368 34.368 0 01409.6 167.36c0-18.944 15.36-34.432 34.112-34.432h136.576a34.368 34.368 0 110 68.928zm0 206.72H443.712c-56.512 0-102.4 46.336-102.4 103.424 0 57.088 45.888 103.36 102.4 103.36h136.576c56.512 0 102.4-46.272 102.4-103.36s-45.888-103.36-102.4-103.36zm0 137.856H443.712A34.368 34.368 0 01409.6 512c0-18.944 15.36-34.432 34.112-34.432h136.576a34.368 34.368 0 110 68.928zm0 206.784H443.712c-56.512 0-102.4 46.336-102.4 103.424 0 57.088 45.888 103.36 102.4 103.36h136.576c56.512 0 102.4-46.272 102.4-103.36s-45.888-103.424-102.4-103.424zm0 137.856H443.712A34.368 34.368 0 01409.6 856.64c0-19.008 15.36-34.496 34.112-34.496h136.576c18.752 0 34.112 15.488 34.112 34.496 0 18.944-15.36 34.432-34.112 34.432zM204.8 822.144c-36.48 0-70.784-14.336-96.64-40.32a137.984 137.984 0 01-39.872-97.536c0-36.864 14.208-71.424 39.936-97.536 25.792-26.048 60.032-40.32 96.576-40.32h68.288v-68.864H204.8C91.712 477.568 0 570.112 0 684.288s91.712 206.784 204.8 206.784V960l102.4-103.36-102.4-103.424v68.928zm614.4-689.28h-68.288v68.992H819.2c36.48 0 70.784 14.336 96.64 40.32 25.792 26.048 39.872 60.672 39.872 97.536 0 36.864-14.208 71.424-39.936 97.536-25.792 25.92-60.032 40.32-96.576 40.32V408.64L716.8 512l102.4 103.36v-68.928c113.088 0 204.8-92.544 204.8-206.72s-91.712-206.784-204.8-206.784z"/>'
  }
})
